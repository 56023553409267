<div class="dialog license-california">
  <div appCustomOverlayScrollbar>
    <div class="license-california__close">
      <mat-icon
        class="mat-icon--smaller"
        svgIcon="close"
        (click)="close()"></mat-icon>
    </div>
    <mat-icon
      class="license-california__icon"
      svgIcon="img-licenseexpiringsoon"></mat-icon>
    <p class="license-california__header">
      Your Service Contract Seller License
      <br />
      may be expiring soon.
    </p>
    <div class="license-california__main-text">
      <p>
        <b>
          The State of California requires dealers selling service contracts to be licensed as a Service
          Contract Seller. It is the responsibility of the dealers to obtain and maintain the proper
          licensing. Information and applications for licensing may be found at
          <a
            class="license-california__link"
            [href]="externalLink"
            target="_blank">
            {{ externalLink }}
          </a>
          . All applications and renewals should be submitted directly by the dealer to the Bureau of
          Household Goods and Services with appropriate fees.
        </b>
      </p>
    </div>
    <div class="license-california__text">
      <p>
        Montage, Inc. dba Montage Furniture Services, assumes no licensing responsibility for a California
        dealer selling its furniture protection plans without proper licensing. If you would like to download
        a copy of instructions which may assist in your Service Contract Seller application,
        <a
          class="license-california__link"
          href="../../../../../assets/pdf/How_to_California_License.pdf"
          target="_blank">
          click here
        </a>
        .
      </p>
    </div>
    <div class="license-california__actions">
      <span class="license-california__action-text">
        I confirm receipt of information related to the Service Contract Seller license in the State of
        California.
      </span>
      <div class="license-california__action-wrapper">
        <button
          class="_common-button--rounded license-california__action-confirm"
          appLoadingButton
          [isLoading]="loading"
          [disabled]="loading"
          mat-flat-button
          (click)="submit()"
          color="primary">
          CONFIRM
        </button>
      </div>
    </div>
  </div>
</div>
