import { ChangeDetectionStrategy, Component,inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { StoresApi } from '@th-common/app/core/api/stores.api';
import { finalize } from 'rxjs/operators';

import { LoadingButtonDirective } from '@shared/directives/loading-button.directive';
import { CustomOverlayScrollbarDirective } from '@shared/standalone/custom-overlay-scrollbar.directive';


@Component({
  selector: 'app-license-california-dialog',
  standalone: true,
  templateUrl: './license-california-dialog.component.html',
  styleUrls: ['./license-california-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CustomOverlayScrollbarDirective,
    MatIconModule,
    MatButtonModule,
    LoadingButtonDirective,
  ],
})
export class LicenseCaliforniaDialogComponent {
  externalLink = 'https://www.bhgs.dca.ca.gov';
  loading = false;
  dialogData: {
    data: {retailerId: number};
  } = inject(MAT_DIALOG_DATA);

  constructor(
    public dialogRef: MatDialogRef<any>,
    private storesApi: StoresApi,
  ) {}

  close() {
    this.dialogRef.close({
      submittedLicenseCalifornia: false,
    });
  }

  submit() {
    this.loading = true;
    this.storesApi
      .postCaliforniaStateLicenseData(this.dialogData.data.retailerId)
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe(
        (response: {lastAcknowledgeDate: string; message?: string}) => {
          this.dialogRef.close({
            submittedLicenseCalifornia: true,
            message: response.message || '',
          });
        },
        error => {
          this.loading = false;
        },
      );
  }
}
