import {
  CurrencyPipe,
  DatePipe,
  DecimalPipe,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
  NgTemplateOutlet,
} from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';

import {
  ITableCell,
  ITableCellCurrency,
  ITableCellDate,
  ITableCellDescription,
  ITableCellFormEl,
  ITableCellLink,
  ITableCellNumber,
  ITableCellRoute,
} from '../../interfaces/table-cell.interface';
import { TTableCell } from '../../types/table-cell.enum';

@Component({
  selector: 'app-table-cell, th-table-cell',
  standalone: true,
  templateUrl: './table-cell.component.html',
  styleUrls: ['./table-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgSwitch,
    MatTooltipModule,
    DatePipe,
    NgSwitchCase,
    NgIf,
    DecimalPipe,
    CurrencyPipe,
    MatIconModule,
    RouterLink,
    NgSwitchDefault,
    NgTemplateOutlet,
    ReactiveFormsModule,
  ],
})

/**
 * A table cell component
 * */
export class ThTableCellComponent {
  @Input() id: string;
  @Input() item: ITableCell;
  @Input() isMobile: boolean;
  @Input() customTemplate: TemplateRef<any>;

  TTableCell = TTableCell;

  get isInline(): boolean {
    return [TTableCell.Inline, TTableCell.Email, TTableCell.Phone].includes(this.item?.type);
  }

  get itemFormControl(): ITableCellFormEl {
    return <any>this.item;
  }

  get itemFormDescription(): ITableCellDescription {
    return <any>this.item;
  }

  get itemFormRoute(): ITableCellRoute {
    return <any>this.item;
  }

  get itemFormLink(): ITableCellLink {
    return <any>this.item;
  }

  get itemFormNumber(): ITableCellNumber {
    return <any>this.item;
  }

  get itemFormCurrency(): ITableCellCurrency {
    return <any>this.item;
  }

  get itemFormDate(): ITableCellDate {
    return <any>this.item;
  }

  isString(item: any): boolean {
    return typeof item === 'string';
  }
}
