<table
  *rxLet="vm$; let vm"
  mat-table
  matSort
  class="th-table"
  [activeSorts]="sorting"
  (multipleSortChange)="handleSortChanged($event)"
  [dataSource]="data">
  <ng-container
    *ngIf="selection"
    matColumnDef="select">
    <th
      mat-header-cell
      class="th-table__cell th-table__cell_header"
      *matHeaderCellDef>
      <mat-checkbox
        *ngIf="data.length > 0"
        (change)="$event ? toggleAllRows() : null"
        [checked]="selection.isAllOnPageSelected$ | push"
        [indeterminate]="selection.isAnyOnPageSelected$ | push"></mat-checkbox>
    </th>
    <td
      mat-cell
      class="th-table__cell"
      *matCellDef="let row">
      <mat-checkbox
        (click)="$event.stopPropagation()"
        (change)="$event ? selectRow(row) : null"
        [checked]="selection.isSelected(row.id.value)"></mat-checkbox>
    </td>
    <td
      mat-footer-cell
      class="th-table__cell th-table__cell_footer"
      *matFooterCellDef></td>
  </ng-container>

  <ng-container
    *ngFor="let col of vm.columns; let first = first; trackBy: trackById"
    [matColumnDef]="this.buildColKey(col)">
    <th
      mat-header-cell
      mat-sort-header
      [disabled]="isSortable ? col.disableSorting : true"
      class="th-table__cell th-table__cell_header"
      [class.th-table__cell_centered]="col.centered"
      [class.th-table__cell_right]="col.alignRight"
      [style.width]="col.width"
      [style.max-width]="col.maxWidth"
      [style.min-width]="col.minWidth"
      *matHeaderCellDef>
      <th-table-col
        [item]="col"
        [isMobile]="breakpointService.isMobileOrTablet$ | push"
        (action)="triggerAction($event)"></th-table-col>
    </th>

    <td
      mat-cell
      class="th-table__cell"
      [class.th-table__cell_right]="col.alignRight"
      [style.vertical-align]="element[col.id]?.verticalAlign"
      [style.width]="col.width"
      [style.max-width]="col.maxWidth"
      [style.min-width]="col.minWidth"
      [attr.data-title]="col.title"
      *matCellDef="let element">
      <th-table-cell
        [id]="col.id"
        [item]="element[col.id]"
        [isMobile]="breakpointService.isMobileOrTablet$ | push"
        [customTemplate]="customCellTemplate"></th-table-cell>

      <ng-container *ngIf="first">
        <ng-container *ngFor="let colMobile of vm.columnsMobile; trackBy: trackById">
          <span *ngIf="mobileColumnName">{{ colMobile.titleMobile }}:</span>
          <th-table-cell
            [id]="col.id"
            [item]="element[colMobile.id]"
            [isMobile]="breakpointService.isMobileOrTablet$ | push"
            [customTemplate]="customCellTemplate"></th-table-cell>
        </ng-container>
      </ng-container>
    </td>

    <td
      mat-footer-cell
      class="th-table__cell th-table__cell_footer"
      *matFooterCellDef></td>
  </ng-container>

  <!-- Actions column -->
  <ng-container matColumnDef="toolbar">
    <th
      mat-header-cell
      class="th-table__cell th-table__cell_header"
      *matHeaderCellDef></th>
    <td
      mat-cell
      *matCellDef="let element; let index = index"
      class="th-table__cell th-table__cell_right th-table__cell_toolbar"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin">
      <ng-template
        cdkConnectedOverlay
        cdkConnectedOverlayPanelClass="table-toolbar-overlay"
        [cdkConnectedOverlayPositions]="toolbarPositions"
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="hoveredRow === index">
        <th-table-cell-toolbar
          [actions]="element.toolbar.options"
          (actionClick)="handleAction($event, element.toolbar.data)"
          (mouseleave)="this.hoveredRow = undefined"></th-table-cell-toolbar>
      </ng-template>
    </td>
    <td
      mat-footer-cell
      class="th-table__cell th-table__cell_footer"
      *matFooterCellDef></td>
  </ng-container>

  <ng-container *ngIf="showTableHeader">
    <tr
      mat-header-row
      class="th-table__row th-table__row_header"
      *matHeaderRowDef="vm.displayColumns; sticky: true"></tr>
  </ng-container>

  <tr
    mat-row
    class="th-table__row"
    [class.th-table__row_clickable]="isClickable"
    [ngStyle]="{ height: rowHeight ? rowHeight : 'auto' }"
    *matRowDef="let index = index; let row; columns: vm.displayColumns"
    (click)="triggerRowClick(index, row)"
    (mouseenter)="hoveredRow = index"></tr>
  <tr
    mat-footer-row
    class="th-table__row th-table__row_footer"
    *matFooterRowDef="vm.displayColumns"></tr>
</table>
