import { AsyncPipe, CurrencyPipe, NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { RxState } from '@rx-angular/state';
import moment from 'moment-timezone';

import { ServiceActionApi } from '@core/api/service-action-api';
import { AppConstants, DEFAULT_FILES_DROP_AREA } from '@core/constants/app.constants';
import { TRetailerAction } from '@core/enums/claim/retailer-action.enum';
import { TDateFieldType } from '@core/enums/date/date-field-type.enum';
import {
  ClaimIncidentPartOrders,
  PartOrders,
  ServerClaimIncidentPartOrders,
} from '@core/interfaces/claims/claimDetails.interface';
import { Files } from '@core/interfaces/claims/files.interface';
import { DialogService } from '@core/services/dialog.service';
import { PromptDialogService } from '@core/services/dialog/prompt-dialog.service';
import { ClaimItemStore } from '@core/store/claim-item/claim-item.store';
import { validateArrayMaxFilesSize } from '@shared/common/form-validators';
import { FormDateComponent } from '@shared/components/form-date/form-date.component';
import { FormInputComponent } from '@shared/components/form-input/form-input.component';
import { TooltipWithLabelComponent } from '@shared/components/tooltip-with-label/tooltip-with-label.component';
import { DialogCoreModule } from '@shared/modules/dialog-core/dialog-core.module';
import { FilesCarouselComponent } from '@shared/modules/files/components/files-carousel/files-carousel.component';
import {
  ClaimInvoiceGridComponent,
} from '@shared/modules/grid/components/claim-invoice-grid/claim-invoice-grid.component';
import { GridModule } from '@shared/modules/grid/grid.module';
import { GridColumnModel } from '@shared/modules/grid/models/grid/grid-column.model';
import { GridFieldModel } from '@shared/modules/grid/models/grid/grid-field';
import { LoaderModule } from '@shared/modules/loader/loader.module';
import { NotificationType } from '@shared/modules/notification/enums/notification-type.enum';
import { NotificationService } from '@shared/modules/notification/services/notification.service';
import { CustomOverlayScrollbarDirective } from '@shared/standalone/custom-overlay-scrollbar.directive';
import {
  FormFilesDropAreaComponent,
} from '@shared/standalone/file-controls/form-files-drop-area/form-files-drop-area.component';

interface IRetailerActionReplacementsDialogState {
  agreedAmount: number;
  loadInProgress: boolean;
}

@Component({
  selector: 'app-retailer-action-replacements-dialog',
  standalone: true,
  templateUrl: './retailer-action-replacements-dialog.component.html',
  styleUrls: ['./retailer-action-replacements-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState],
  imports: [
    FormInputComponent,
    MatIconModule,
    AsyncPipe,
    CurrencyPipe,
    DialogCoreModule,
    CustomOverlayScrollbarDirective,
    FormDateComponent,
    NgClass,
    FormsModule,
    GridModule,
    NgIf,
    MatLegacyTooltipModule,
    FormFilesDropAreaComponent,
    LoaderModule,
    ClaimInvoiceGridComponent,
    TooltipWithLabelComponent,
    MatButtonModule,
    FilesCarouselComponent,
  ],
})
export class RetailerActionReplacementsDialogComponent implements OnInit {
  loadInProgress$ = this.state.select('loadInProgress');
  today: Date = new Date();
  minDate: any = AppConstants.minDateDatepicker;
  TDateFieldType = TDateFieldType;
  agreedAmount$ = this.state.select('agreedAmount');

  columnCost = 'costAmount';
  gridColumns: GridColumnModel[] = [
    new GridColumnModel('item', 'Item', null, false),
    new GridColumnModel('name', 'Name', null, false),
    new GridColumnModel('quantity', 'Quantity', null, false),
    new GridColumnModel(this.columnCost, 'Extended Cost', null, false),
  ];
  initialData: {parts: any[]; gridFields: GridFieldModel[]} = {
    parts: [],
    gridFields: [],
  };
  partOrders: ClaimIncidentPartOrders = {
    actualDeliveryDate: '',
    purchaseOrderNumber: null,
  };
  objectOfAction = '';
  tRetailerAction = TRetailerAction;
  filesUploadAreRequired = false;
  tableInvalid = false;
  filesDropAreaInvalidState = false;
  filesUploadTitle = 'Upload Copy of Manufacturer’s Invoice';

  filesForm: FormArray<FormControl<Files>>;

  dropArea = {
    ...DEFAULT_FILES_DROP_AREA,
  };
  promptDialogService = inject(PromptDialogService);
  dialogData: {
    data: {dialogTitle; retailerAction: TRetailerAction; serviceActionId; serviceActionType};
  } = inject(MAT_DIALOG_DATA);

  constructor(
    public dialogRef: MatDialogRef<any>,
    protected serviceActionApi: ServiceActionApi,
    private notification: NotificationService,
    private readonly claimItemStore: ClaimItemStore,
    private readonly dialogService: DialogService,
    private readonly fb: FormBuilder,
    private readonly state: RxState<IRetailerActionReplacementsDialogState>,
  ) {}

  ngOnInit(): void {
    switch (this.dialogData.data.retailerAction) {
      case TRetailerAction.ReplacementEvenExchange:
        this.filesUploadAreRequired = true;
        this.filesForm = this.fb.array<Files>([], [Validators.required, validateArrayMaxFilesSize()]);
        this.objectOfAction = 'replacements';
        break;
      case TRetailerAction.EZReplacementEvenExchange:
        this.filesForm = this.fb.array<Files>([], [validateArrayMaxFilesSize()]);
        this.filesUploadTitle = 'Upload Copy of Delivery Receipt';
        this.objectOfAction = 'replacements';
        break;
      case TRetailerAction.ReplacementReselection:
        this.filesUploadAreRequired = true;
        this.filesForm = this.fb.array<Files>([], [Validators.required, validateArrayMaxFilesSize()]);
        this.objectOfAction = 'reselections';
        break;
      case TRetailerAction.EZReplacementReselection:
        this.filesForm = this.fb.array<Files>([], [validateArrayMaxFilesSize()]);
        this.filesUploadTitle = 'Upload Copy of Delivery Receipt';
        this.objectOfAction = 'reselections';
        break;
      case TRetailerAction.PartOrderEnterDeliveryAndPaymentRequest:
        this.filesUploadAreRequired = true;
        this.filesForm = this.fb.array<Files>([], [Validators.required, validateArrayMaxFilesSize()]);
        this.objectOfAction = 'parts';
        break;
    }

    this.getInitialData(this.dialogData.data.serviceActionId, this.dialogData.data.serviceActionType);
  }

  getInitialData(serviceActionId: number, serviceActionType: number): void {
    this.state.set({
      loadInProgress: true,
    });
    this.serviceActionApi.getServiceActionDetails(serviceActionId, serviceActionType).subscribe(
      (data: ServerClaimIncidentPartOrders) => {
        this.state.set({
          loadInProgress: false,
        });
        if (data.parts.length === 0) {
          this.onClose();
          this.promptDialogService.openPrompt({
            message: 'No parts are available. Please submit a portal note for this claim to have Montage correct it.',
            buttonYes: 'OK',
          });
          return;
        }
        if (
          data.parts
          && data.parts.length
          && (this.dialogData.data.retailerAction === TRetailerAction.ReplacementEvenExchange
            || this.dialogData.data.retailerAction === TRetailerAction.ReplacementReselection
            || this.dialogData.data.retailerAction === TRetailerAction.PartOrderEnterDeliveryAndPaymentRequest)
        ) {
          const parts = [];
          data.parts.forEach((part, i) =>
            parts.push({
              id: part.id,
              item: i + 1,
              name: part.name,
              quantity: part.quantity,
              costAmount: null,
            }),
          );
          this.initialData.parts = [...parts];
        }
        if (
          data[AppConstants.fieldNameMaximizedAuthorizedAmount]
          && this.dialogData.data.retailerAction === TRetailerAction.ReplacementReselection
        ) {
          this.initialData.gridFields.push(
            new GridFieldModel(
              AppConstants.fieldNameMaximizedAuthorizedAmount,
              data[AppConstants.fieldNameMaximizedAuthorizedAmount],
            ),
          );
        }
        if (data.estimatedDeliveryDate) {
          this.partOrders.actualDeliveryDate = data.estimatedDeliveryDate;
        }
        this.state.set({
          agreedAmount: data[AppConstants.fieldNameMaximizedAuthorizedAmount],
        });
      },
      error => {
        this.state.set({
          loadInProgress: false,
        });
        this.notification.next({
          message: error.message || 'error...',
          type: NotificationType.Error,
          duration: 20000,
        });
      },
    );
  }

  submitRetailerActionReplacementsDialog(): void {
    const loadInProgress = this.state.get('loadInProgress');
    if (loadInProgress) {
      return;
    }
    if (
      this.partOrders.actualDeliveryDate
      && !this.tableInvalid
      && !this.filesDropAreaInvalidState
      && (this.filesUploadAreRequired ? this.filesForm.value.length : true)
    ) {
      const files = [...this.filesForm.value];
      this.state.set({
        loadInProgress: true,
      });
      const actualDeliveryDate = { actualDeliveryDate: moment(this.partOrders.actualDeliveryDate).utc(true).format() };
      const partOrders = { ...this.partOrders, ...actualDeliveryDate };
      this.serviceActionApi.requestPayment(files, this.dialogData.data.serviceActionId, partOrders).subscribe(
        serviceAction => {
          if (serviceAction.errorMessage) {
            this.notification.next({
              message: serviceAction.errorMessage,
              type: NotificationType.Error,
              duration: 8000,
            });
          } else {
            this.claimItemStore.updateServiceAction();
          }
          this.dialogRef.close({
            submittedRetailerActionReplacementsDialog: true,
          });
        },
        error => {
          this.state.set({
            loadInProgress: false,
          });
          this.notification.next({
            message: error.message || 'error...',
            type: NotificationType.Error,
            duration: 20000,
          });
        },
      );
    }
  }

  updatePartsData(value: PartOrders): void {
    this.partOrders = { ...this.partOrders, ...value };
  }

  setTableInvalidState(value: boolean): void {
    this.tableInvalid = value;
  }

  setFilesDropAreaInvalidState(value: boolean): void {
    this.filesDropAreaInvalidState = value;
  }

  checkIfThereAreUnsavedData(): boolean {
    let result = false;
    if ((this.filesUploadAreRequired && this.filesForm.value.length) || this.partOrders.actualDeliveryDate) {
      return true;
    }
    if (this.partOrders.parts && this.partOrders.parts.length) {
      if (this.partOrders.taxAmount || this.partOrders.deliveryAmount) {
        return true;
      }
      this.partOrders.parts.forEach(part => {
        if (part.costAmount > 0) {
          result = true;
          return;
        }
      });
    }
    return result;
  }

  onClose(): void {
    const loadInProgress = this.state.get('loadInProgress');
    if (loadInProgress) {
      return;
    }
    if (this.checkIfThereAreUnsavedData()) {
      this.dialogService.canLeaveDefaultConfirmationObservable(null, true).subscribe(canLeave => {
        if (canLeave) {
          this.dialogRef.close({
            submittedRetailerActionReplacementsDialog: false,
          });
        }
      });
    } else {
      this.dialogRef.close({
        submittedRetailerActionReplacementsDialog: false,
      });
    }
  }

  isRequestPaymentZeroDollarEnhancement(value: boolean): void {
    switch (this.dialogData.data.retailerAction) {
      case TRetailerAction.PartOrderEnterDeliveryAndPaymentRequest:
        this.filesUploadAreRequired = !value;
        break;
    }
  }

  removeFiles(fileIndex: number): void {
    const updatedFiles = this.filesForm.value.filter(file => fileIndex !== file.index);
    this.filesForm.clear();
    updatedFiles.forEach(file => {
      this.filesForm.push(this.fb.control(file));
    });
  }
}
