<div class="dialog dialog--side retailer-action-replacements-dialog">
  <app-base-header [headerTitle]="dialogData.data.dialogTitle" (closeClick)="onClose()"></app-base-header>
    <p class="dialog__content help-text help-text--required retailer-action-replacements-dialog__required-section">Required</p>
    <div class="retailer-action-replacements-dialog__loader-wrapper" [ngClass]="{'in-progress': loadInProgress$ | async}">
      <div appCustomOverlayScrollbar class="dialog__content retailer-action-replacements-dialog__form-wrapper">
        <div class="block__content">
          <div class="fields-block">
            <app-form-date class="field field--half"
                           [(ngModel)]="partOrders.actualDeliveryDate"
                           name="actualDeliveryDate"
                           [label]="'Actual Delivery Date'"
                           [required]="true"
                           [max]="today"
                           [min]="minDate"></app-form-date>
            <span class="field help-text--optional">If there are multiple {{objectOfAction}} with different actual delivery dates, please enter the <strong>last</strong> date.</span>
          </div>
        </div>

        <div class="block__content retailer-action-replacements-dialog__tile" *ngIf="initialData.parts.length">
          <app-claim-invoice-grid [initialData]="initialData"
                                  [columns]="gridColumns"
                                  [requiredColumnName]="columnCost"
                                  (tableInvalidState)="setTableInvalidState($event)"
                                  [requiredColumnTooltip]="'Please enter the cost for each item as shown on the manufacturer\'s invoice.'"
                                  (isRequestPaymentZeroDollarEnhancement)="isRequestPaymentZeroDollarEnhancement($event)"
                                  (updatedPartsData)="updatePartsData($event)"></app-claim-invoice-grid>
        </div>

        <div class="block__content"
             *ngIf="dialogData.data.retailerAction === tRetailerAction.EZReplacementEvenExchange
             || dialogData.data.retailerAction === tRetailerAction.EZReplacementReselection">
          <div class="field field--third retailer-action-replacements-dialog__agreed-amount-section">
            <mat-icon class="mat-icon--small" svgIcon="checkmark-new"></mat-icon>
            <span class="retailer-action-replacements-dialog__agreed-amount-value">{{agreedAmount$ | async | currency:'$'}}</span>
            <span class="retailer-action-replacements-dialog__agreed-amount-title">Agreed Amount</span>
          </div>
          <app-tooltip-with-label [tooltipText]="'You have elected to take part in the EZ replacement program and agreed to be reimbursed a fixed percent of the original retail price paid for the item(s) replaced. Please refer to the purchase order for more details.'"
                                  [tooltipPosition]="'right'"></app-tooltip-with-label>
        </div>

        <div class="block__content">
          <div class="fields-block">
            <app-form-input class="field field--third"
                            [(ngModel)]="partOrders.purchaseOrderNumber"
                            [label]="'Retailer\'s Purchase Order'"></app-form-input>
          </div>
        </div>

        <div class="block__content">
          <p class="block__title retailer-action-replacements-dialog__title">
            <span [class.help-text--required-right]="filesUploadAreRequired">{{filesUploadTitle}}</span>
            <span class="help-text--optional" *ngIf="!filesUploadAreRequired">optional</span>
            <span class="tooltip" *ngIf="filesUploadAreRequired">
              <mat-icon svgIcon="help_outline" class="_common-icon--red-help"
                        #tooltip="matTooltip"
                        matTooltip="A copy of the manufacturer`s invoice for the item(s) replaced will be required before reimbursement can be processed."
                        matTooltipPosition="right"></mat-icon>
            </span>
          </p>
          <div class="files-preview-block">
            <app-files-carousel *ngIf="filesForm.value.length > 0"
                                [files]="filesForm.value"
                                [showRemove]="(loadInProgress$ | async) === false"
                                [disableControls]="loadInProgress$ | async"
                                (removeFileByIndex)="removeFiles($event)"></app-files-carousel>
          </div>
          <app-form-files-drop-area [formFilesArray]="filesForm"
                                    [labelDescription]="dropArea.labelDescription"
                                    [labelDescriptionForMobile]="dropArea.labelDescriptionForMobile"
                                    [multiple]="dropArea.maxFilesNumber === null"
                                    [pictureIcon]="dropArea.svgIcon"
                                    [maxFilesNumber]="dropArea.maxFilesNumber"
                                    [docType]="dropArea.docType"></app-form-files-drop-area>
        </div>
      </div>
      <div *ngIf="loadInProgress$ | async" class="retailer-action-replacements-dialog__loader">
        <app-loader class="base-loader"></app-loader>
      </div>
    </div>
    <div class="dialog__content inline-buttons inline-buttons--right">
      <button (click)="submitRetailerActionReplacementsDialog()" class="inline-buttons__button _common-button--rounded"
              mat-flat-button color="primary"
              [disabled]="(loadInProgress$ | async) || !partOrders.actualDeliveryDate
                          || tableInvalid
                          || filesDropAreaInvalidState
                          || !(filesUploadAreRequired ? filesForm.value.length : true)">REQUEST PAYMENT</button>
    </div>
</div>
