<div class="dialog license-florida">
  <div appCustomOverlayScrollbar class="dialog__content" *ngIf="!dialogData.data.allRetailersDontHaveFloridaLicenses; else allRetailersDontHaveLicenses">
    <div class="license-florida__close">
      <mat-icon class="mat-icon--smaller" svgIcon="close" (click)="close()"></mat-icon>
    </div>
    <mat-icon class="license-florida__icon" svgIcon="img-licenseexpiringsoon"></mat-icon>
    <p class="license-florida__header">Florida State Licensing</p>
    <div class="license-florida__main-text">
      <p><b>The State of Florida requires retailers selling protection plans to obtain a Service Warranty Sales license. It is the responsibility of the retailers to obtain and maintain proper licensing. All applications should be submitted directly by the retailer to the Florida Department of Financial Services Bureau of Licensing with appropriate fees.</b></p>
    </div>
    <div class="license-florida__text">
      <p>
        Montage, Inc. assumes no licensing responsibility for a Florida retailer selling its furniture protection plans without proper licensing. If you would like to download a copy of instructions which may assist in your Service Warranty Sales application,
        <a class="license-florida__link" href="../../../../../assets/pdf/How_to_Florida_License.pdf" target="_blank">click here</a>.
      </p>
      <br/>
      <p>
        Upon receipt of your Florida License number, please forward the license details to our Sales Support team at
        <a class="license-florida__mailto" [href]="'mailto:' + contactEmail">{{contactEmail}}</a> for proper consideration.
      </p>
    </div>
  </div>

  <ng-template #allRetailersDontHaveLicenses>
    <div class="dialog__content dialog__content--small">
      <div class="license-florida__close">
        <mat-icon class="mat-icon--smaller" svgIcon="close" (click)="close()"></mat-icon>
      </div>
      <mat-icon class="license-florida__icon" svgIcon="img-licenseexpiringsoon"></mat-icon>
      <p class="license-florida__header">Florida State Licensing</p>
      <div class="license-florida__main-text">
        <p><b>All retailers selling plans are currently in Florida and do not have valid Florida State License data, plan registration is not possible.</b></p>
      </div>
    </div>
  </ng-template>

</div>
