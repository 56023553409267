import { ApplicationRef, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Claim } from '@core/interfaces/claims/claim.interface';
import { THubMethods } from '@core/models/hub-event.model';
import { BaseHub } from '@core/services/hubs/base.hub';
import { ModalDialogService } from '@shared/components/modal-dialog/modal-dialog.service';
import { ClaimSuccessComponent } from '@shared/modules/side-dialog/components/claim-success/claim-success.component';

import { RetailerPlanSuccessComponent } from '../../shared/components/retailer-plan-success/retailer-plan-success.component';

@Injectable({
  providedIn: 'root',
})
export class DealerMainHub {
  constructor(
    private matDialog: MatDialog,
    private readonly appRef: ApplicationRef,
    public readonly baseHub: BaseHub,
    public readonly modalDialogService: ModalDialogService,
  ) {
    baseHub.setConfig('claims', [
      THubMethods.ProductsCreated,
      THubMethods.PlanCreated,
      THubMethods.DocsUploaded,
      THubMethods.ClaimStatusChange,
      THubMethods.ProtectionPlanCreated,
    ]);
    this.subscribeToUpdates();
  }

  startSignalR() {
    this.baseHub.startSignalR();
  }

  subscribeToUpdates() {
    this.baseHub.eventBus$.subscribe(event => {
      switch (event.name) {
        case THubMethods.ProductsCreated:
          if (event.data.claim) {
            this.openClaimSuccessDialog(event.data.claim);
          }
          break;
        case THubMethods.ProtectionPlanCreated:
          if (event.data.createdPlans || event.data.failedPlans) {
            this.openRetailerPlanSuccessDialog(event.data);
          }
          break;
      }
    });
  }

  openClaimSuccessDialog(claim: Claim): void {
    this.modalDialogService.open(
      {
        title: 'Claim success',
        data: {
          claim,
        },
      },
      ClaimSuccessComponent,
      {
        disableClose: true,
        width: 'auto',
      },
    );
    this.appRef.tick();
  }

  openRetailerPlanSuccessDialog(data: any): void {
    this.matDialog.open(RetailerPlanSuccessComponent, {
      disableClose: true,
      data,
    });
    this.appRef.tick();
  }
}
