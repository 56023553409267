<app-side-dialog-animation [class.dialog--header-alert]="currentSubStep === preSteps.Registration">
  <app-base-header
    headerTitle="Register Protection Plan"
    (closeClick)="onClose()">
    <app-alert-message
      *ngIf="currentStep.step === steps.Consumer && currentSubStep === preSteps.Registration"
      [title]="'NOTE:'"
      [message]="consumerStepAlertMessage$ | async"
      [type]="'warning'"></app-alert-message>
  </app-base-header>
  <ng-container *ngIf="!loading; else sideLoader">
    <app-side-dialog-container>
      <app-side-dialog-nav>
        <app-wizard-navigation
          [navigationList]="navigationList"
          [currentStep]="currentStep"
          [currentSubStep]="currentSubStep$ | async"
          [navigationItems]="navigationItems"
          [form]="form"></app-wizard-navigation>
      </app-side-dialog-nav>
      <app-side-dialog-content>
        <form
          [formGroup]="form"
          class="claim-form__form"
          spellcheck="false">
          <div
            [hidden]="currentStep.step !== steps.Consumer"
            class="section">
            <h2
              class="section__title"
              *ngIf="!navigationItems.consumer.readonly">
              Consumer
            </h2>
            <div [hidden]="!(currentSubStep === preSteps.Search || currentSubStep === preSteps.Select)">
              <app-pre-register-retailer-plan
                [currentSubStep]="currentSubStep"
                (nextStep)="nextStep()"
                (goToRegistrationStep)="goToRegistrationStep($event)"
                (consumerClicked)="onConsumerClicked($event)"></app-pre-register-retailer-plan>
            </div>
            <div [hidden]="currentSubStep !== preSteps.Registration">
              <app-consumer-form
                [form]="form"
                [consumerForm]="form.get('consumer.info')"
                [emailRequired]="isEmailRequired$ | async"
                [readonly]="navigationItems.consumer.readonly"
                [hiddenBlocks]="['contactPreferences']"></app-consumer-form>
              <div class="section">
                <div class="consumer-tooltip">
                  <h3 class="section__title">Consumer Address</h3>
                </div>
                <app-address-readonly
                  *ngIf="navigationItems.consumer.readonly; else consumerAddressForm"
                  [address]="form.get('consumer.consumerAddress').value"></app-address-readonly>
                <ng-template #consumerAddressForm>
                  <app-address-form
                    [form]="form.get('consumer.consumerAddress')"
                    (inputChanged)="updateServiceAddress()"></app-address-form>
                  <app-address-suggest
                    [addressForm]="form.get('consumer.consumerAddress')"
                    (isInProgress)="addressVerificationInProgress($event)"></app-address-suggest>
                </ng-template>
              </div>
              <div class="section">
                <div class="claim-form__switch-header consumer-tooltip section__title">
                  <h3>Service Address</h3>
                  <app-switch
                    class="claim-form__same-switch"
                    type="checkbox"
                    [title]="'Same as consumer address'"
                    [formControl]="form.get('consumer.isServiceAddressSame')"></app-switch>
                </div>
                <div>
                  <app-address-form
                    [form]="form.get('consumer.serviceAddress')"
                    [blocked]="form.get('consumer.isServiceAddressSame').value"></app-address-form>
                  <app-address-suggest
                    *ngIf="!form.get('consumer.isServiceAddressSame').value"
                    [addressForm]="form.get('consumer.serviceAddress')"
                    [isServiceAddressSame]="form.get('consumer.isServiceAddressSame')"
                    (isInProgress)="addressVerificationInProgress($event)"></app-address-suggest>
                </div>
              </div>
            </div>
          </div>
          <div
            [hidden]="currentStep.step !== steps.Sale"
            class="section">
            <h2 class="section__title">Sale</h2>
            <div class="retailer-plan__sale">
              <div class="retailer-plan__sale-fields">
                <div class="fields-block">
                  <app-retailer-select
                    class="field"
                    [required]="true"
                    [formControl]="form.get('consumerPlan.retailerId')"
                    [label]="'Retailer'"
                    (selectedItem)="selectRetailer($event)"></app-retailer-select>
                </div>
                <div class="fields-block">
                  <app-form-date
                    class="field"
                    [formControl]="form.get('consumerPlan.purchaseDate')"
                    [label]="'Purchase Date'"
                    [required]="true"
                    [min]="minPurchaseDate"
                    [max]="today"></app-form-date>
                </div>
                <div class="fields-block">
                  <app-form-input
                    class="field"
                    [formControl]="form.get('consumerPlan.receiptNumber')"
                    [required]="true"
                    [label]="'Receipt Number'"></app-form-input>
                </div>
              </div>
              <div
                class="retailer-plan__address"
                *ngIf="!!selectedRetailer">
                <div class="field__label retailer-plan__address-label">Address</div>
                <div
                  class="retailer-plan__address-text"
                  [innerHTML]="
                    selectedRetailerAddress
                      ? (selectedRetailerAddress | appEmptyValue | safe : 'html')
                      : 'No data.'
                  "></div>
              </div>
              <div class="retailer-plan__sale-alert">
                <app-alert-message
                  [title]="'NOTE:'"
                  [message]="consumerStepAlertMessage$ | async"
                  [type]="'warning'"></app-alert-message>
              </div>
            </div>
          </div>
          <div
            [hidden]="currentStep.step !== steps.Plan"
            class="section"
            formGroupName="plan">
            <div class="wizard__step-header section__title">
              <h2>Plan Information</h2>
              <div
                class="wizard__step-header-action"
                *ngIf="this.form.get('plan')['controls'].length > 1"
                (click)="removePlan(currentSubStep)">
                <mat-icon
                  class="wizard__step-header-action-icon"
                  svgIcon="close"></mat-icon>
                <span class="wizard__step-header-action-text">Remove this plan</span>
              </div>
            </div>
            <ng-container *ngFor="let plan of form.get('plan')['controls']; let i = index">
              <div [hidden]="i !== currentSubStep">
                <app-protection-plan-form
                  [form]="plan"
                  [retailerId]="form.get('consumerPlan.retailerId').value"
                  (protectionPlanSelected)="protectionPlanSelected($event)"
                  (plans)="plansUpdated($event)"
                  [currentStep]="currentStep.step"
                  [purchaseDate]="form.get('consumerPlan.purchaseDate').value"
                  [selectedPlansIdsList]="selectedPlansIdsList"></app-protection-plan-form>
              </div>
            </ng-container>

            <div class="address-suggest">
              <h4>Add Another Plan to This Consumer and Purchase</h4>
              <div class="address-suggest__content">
                You can register multiple protections plans for this purchase and consumer, each one with its
                own covered products. To add another plan to the sale, click ADD ANOTHER PLAN.
              </div>
            </div>
            <div class="claim-form__header-buttons-block">
              <div class="claim-form__header-buttons">
                <button
                  type="button"
                  mat-flat-button
                  color="primary"
                  (click)="addPlan()"
                  [disabled]="
                    !(
                      form.get('plan')['controls'][currentSubStep] &&
                      form.get('plan')['controls'][currentSubStep].valid
                    )
                  ">
                  Add another plan
                </button>
              </div>
            </div>
          </div>
          <div
            [hidden]="currentStep.step !== steps.UploadReceipt"
            class="section">
            <h2 class="section__title">Upload Receipt</h2>
            <p class="help-text">
              It is recommended that you upload your receipt now in order to streamline any future claims on
              behalf of your consumers.
            </p>
            <div>
              <div class="files-preview-block">
                <app-files-preview-new
                  [files]="filesForm.value"
                  [showRemove]="true"
                  [showCaptions]="false"
                  [showDocumentName]="true"
                  [disableControls]="submitInProgress"
                  (removeFileByIndex)="removeFiles($event)"></app-files-preview-new>
              </div>
              <app-form-files-drop-area
                [formFilesArray]="filesForm"
                [labelDescription]="dropArea.labelDescription"
                [labelDescriptionForMobile]="dropArea.labelDescriptionForMobile"
                [multiple]="dropArea.maxFilesNumber === null"
                [pictureIcon]="dropArea.svgIcon"
                [maxFilesNumber]="dropArea.maxFilesNumber"
                [docType]="dropArea.docType"></app-form-files-drop-area>
            </div>
          </div>
          <div *ngIf="!(currentStep.step === steps.Consumer && currentSubStep === preSteps.Search)">
            <app-wizard-step-buttons
              [form]="form"
              [currentStep]="currentStep"
              [currentSubStep]="currentSubStep"
              [submitInProgress]="submitInProgress"
              [disableSubmitButton]="filesForm.invalid"
              (prevStep)="prevStep()"
              (nextStep)="nextStep()"
              (submitForm)="submitForm()"
              [nextButtonDisabled]="(nextButtonDisabled$ | async) || (addressVerificationInProgress$ | async)"
              [nextButtonInProgress]="nextButtonInProgress"
              [nextButtonShow]="
                !(
                  currentStep.step === steps.Consumer &&
                  (currentSubStep === preSteps.Search || currentSubStep === preSteps.Select)
                )
              "
              [backButtonDisabled]="
                (backButtonDisabled$ | async) || (addressVerificationInProgress$ | async)
              "></app-wizard-step-buttons>
          </div>
        </form>
      </app-side-dialog-content>
    </app-side-dialog-container>
  </ng-container>
</app-side-dialog-animation>
<ng-template #sideLoader>
  <app-side-dialog-loader></app-side-dialog-loader>
</ng-template>
