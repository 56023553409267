import { Injectable } from '@angular/core';
import { IProcessStage } from '@th-common-retailer/core/interfaces/process-stage.interface';
import { ClaimDetailsComponent } from '@th-common-retailer/dialogs/claim-details/claim-details.component';
import {
  ClaimDetailsPopupCellComponent,
} from '@th-common-retailer/dialogs/claim-details/containers/claim-details-popup-cell/claim-details-popup-cell.component';
import {
  ClaimsPageService,
  IServiceActionFilterCheckbox,
} from '@th-common-retailer/shared/pages/claims/claims-page.service';
import { Observable } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';

import { AppConstants } from '@core/constants/app.constants';
import { DIALOG_CONFIG_CUSTOM_ANIMATION } from '@core/constants/dialog.constants';
import { Feature } from '@core/enums/feature.enum';
import { TServiceActionTileStage } from '@core/enums/service-action/service-action-tile-stage.enum';
import { TServiceActionType } from '@core/enums/service-action/service-action-type.enum';
import { ClaimDetails, IClaimDetailsModel } from '@core/interfaces/claims/claimDetails.interface';
import { AdvancedSearchService } from '@core/services/advanced-search.service';
import { FeatureService } from '@core/services/feature.service';
import { ClaimsStore } from '@core/store/claims/claims.store';
import { UserStore } from '@core/store/user/user.store';
import { ModalDialogService } from '@shared/components/modal-dialog/modal-dialog.service';
import { BaseClaimDetailsTablesConfig } from '@shared/modules/claim-details/classes/base-claim-details-tables-config';
import { ClaimFlaggedCellComponent } from '@shared/modules/claim-grid/components/claim-flagged-cell/claim-flagged-cell.component';
import { ClaimStagesCellComponent } from '@shared/modules/claim-grid/components/claim-stages-cell/claim-stages-cell.component';
import { ClaimStatusCellComponent } from '@shared/modules/claim-grid/components/claim-status-cell/claim-status-cell.component';
import { GridDateCellComponent } from '@shared/modules/grid/components/grid-date-cell/grid-date-cell.component';
import { GridColumnModel } from '@shared/modules/grid/models/grid/grid-column.model';

import { getTileSearchRequest, RetailerTileFilter } from '../../core/constants/tile-stage-filter.constant';

@Injectable({
  providedIn: 'root',
})
export class RetailerClaimsPageService extends ClaimsPageService {
  showPortalTypeTabs = true;
  defaultClaimType$ = this.userStore.select$('session', 'defaultClaimType');

  processStages: IProcessStage[] = [
    {
      id: RetailerTileFilter.getActionTypeTileStageKey(TServiceActionType.PartOrder),
      name: 'Part Orders',
      features: [Feature.HasPPPartOrders],
      children: [
        {
          id: RetailerTileFilter.getActionTypeTileStageKey(TServiceActionType.PartOrder, TServiceActionTileStage.OrderParts),
          name: AppConstants.serviceActionTileStages[TServiceActionTileStage.OrderParts],
        },
        {
          id: RetailerTileFilter.getActionTypeTileStageKey(TServiceActionType.PartOrder, TServiceActionTileStage.EnterDelivery),
          name: AppConstants.serviceActionTileStages[TServiceActionTileStage.EnterDelivery],
        },
      ],
    },
    {
      id: RetailerTileFilter.getActionTypeTileStageKey(TServiceActionType.Replacement),
      name: 'Exchange',
      features: [Feature.HasPPReplacements],
      children: [
        {
          id: RetailerTileFilter.getActionTypeTileStageKey(TServiceActionType.Replacement, TServiceActionTileStage.OrderReplacements),
          name: AppConstants.serviceActionTileStages[TServiceActionTileStage.OrderReplacements],
        },
        {
          id: RetailerTileFilter.getActionTypeTileStageKey(TServiceActionType.Replacement, TServiceActionTileStage.EnterDelivery),
          name: AppConstants.serviceActionTileStages[TServiceActionTileStage.EnterDelivery],
        },
      ],
    },
  ];

  tileFilter = RetailerTileFilter;
  getTileSearchRequest = getTileSearchRequest;

  claimWarrantyColumns: GridColumnModel[] = BaseClaimDetailsTablesConfig.claimWarrantyColumns;
  claimProductsColumns: GridColumnModel[] = BaseClaimDetailsTablesConfig.claimProductsColumns;

  constructor(
    private readonly modalDialogService: ModalDialogService,
    private readonly userStore: UserStore,
    private readonly claimsStore: ClaimsStore,
    private readonly advancedSearchService: AdvancedSearchService,
    private readonly featureService: FeatureService,
  ) {
    super();
  }

  openClaimDetailsDialog(claimId: number, singleView = false): void {
    this.modalDialogService.open({
      title: 'Claim Details',
      data: {
        claimInfo: { id: claimId },
        singleView,
      },
    }, ClaimDetailsComponent, DIALOG_CONFIG_CUSTOM_ANIMATION);
  }

  getDefaultColumns(showFlag = true): GridColumnModel[] {
    return [
      new GridColumnModel('claimNumber', 'Claim #', ClaimDetailsPopupCellComponent, false),
      new GridColumnModel('claimStatus', 'Status', ClaimStatusCellComponent),
      new GridColumnModel('claimStage', 'Stage', ClaimStagesCellComponent),
      new GridColumnModel('retailerName', 'Store', null),
      new GridColumnModel('dateOpen', 'Opened', GridDateCellComponent),
      new GridColumnModel('dateClosed', 'Closed', GridDateCellComponent),
      new GridColumnModel('receiptNumber', 'Receipt Number', null),
      new GridColumnModel('consumerName', 'Consumer', null),
      new GridColumnModel(
        'isFlaggedForRetailerReview',
        'Review',
        ClaimFlaggedCellComponent,
        true,
        true,
        showFlag,
      ),
    ];
  }

  getAdvancedSearchActionFilters(): Observable<IServiceActionFilterCheckbox[]> {
    return this.defaultClaimType$.pipe(
      switchMap(defaultClaimType =>
        this.claimsStore.select$('actionTiles').pipe(
          withLatestFrom(this.claimsStore.select$('statTiles')),
          map(tiles => {
            console.log(tiles);
            const tileSearch = { ...this.advancedSearchService.tileSearch };
            return tiles
              .flat()
              .filter(tile => (
                tile.claimType === defaultClaimType
                && this.featureService.allowedMany(tile.features)
                && !tile.hideCheckbox
              ))
              .map(tile => ({
                ...tile,
                title: tile.checkboxTitle || tile.title,
                checked: !!tileSearch && tileSearch.tileId === tile.id,
              }));
          }),
        ),
      ),
    );
  }

  showCompleteRepairBtn(claim: ClaimDetails): boolean {
    return false;
  }

  completeJewelryClaim(claim: IClaimDetailsModel): Observable<boolean> {
    return undefined;
  }
}
