<div
  [ngSwitch]="item.type"
  class="th-table-cell"
  [class.th-table-cell_inline]="isInline"
  [matTooltip]="item.tooltip"
  matTooltipPosition="right">
  <ng-container *ngSwitchCase="TTableCell.Inline">
    <span class="th-table-cell__type-inline" [matTooltip]="item.tooltip || !item.value ? null : item.value.toString()" matTooltipShowDelay="1000">{{ item.value }}</span>
  </ng-container>

  <ng-container *ngSwitchCase="TTableCell.DesktopInline">
    <span [class.th-table-cell__type-inline]="!isMobile">{{ item.value }}</span>
  </ng-container>

  <ng-container *ngSwitchCase="TTableCell.Date">
    {{ itemFormDate.value | date : "shortDate" }}
  </ng-container>

  <ng-container *ngSwitchCase="TTableCell.Time">
    {{ itemFormDate.value | date : "shortTime" }}
  </ng-container>

  <ng-container *ngSwitchCase="TTableCell.Email">
    <a
      *ngIf="item.value"
      href="mailto: {{ item.value }}">
      <ng-container *ngIf="!isMobile; else emailIcon">{{ item.value }}</ng-container>
      <ng-template #emailIcon><mat-icon>email</mat-icon></ng-template>
    </a>
  </ng-container>

  <ng-container *ngSwitchCase="TTableCell.Phone">
    <ng-container *ngIf="!isMobile; else phoneIcon">
      {{ item.value }}
    </ng-container>
    <ng-template #phoneIcon>
      <a
        *ngIf="item.value"
        href="tel: {{ item.value }}">
        <mat-icon>phone</mat-icon>
      </a>
    </ng-template>
  </ng-container>

  <ng-container *ngSwitchCase="TTableCell.Number">
    {{ itemFormNumber.value | number : itemFormNumber.format : "en-US" }}
  </ng-container>

  <ng-container *ngSwitchCase="TTableCell.Currency">
    {{ itemFormCurrency.value | currency : "USD" : "symbol" : itemFormCurrency.format }}
  </ng-container>

  <ng-container *ngSwitchCase="TTableCell.Link">
    <a
      [href]="itemFormLink.ref"
      [target]="itemFormLink.target"
      class="th-table-cell__type-link">
      {{ itemFormLink.value }}
    </a>
  </ng-container>

  <ng-container *ngSwitchCase="TTableCell.Route">
    <a
      [routerLink]="itemFormRoute.route"
      class="th-table-cell__type-link">
      <ng-container *ngIf="itemFormRoute.icon; else routeTitle">
        <mat-icon>{{ itemFormRoute.icon }}</mat-icon>
      </ng-container>
      <ng-template #routeTitle>
        {{ itemFormRoute.value }}
      </ng-template>
    </a>
  </ng-container>

  <ng-container *ngSwitchCase="TTableCell.WithDescription">
    <div>
      {{ itemFormDescription.value }}
      <div
        *ngIf="itemFormDescription.description"
        class="th-table-cell__description">
        {{ itemFormDescription.description }}
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="TTableCell.Boolean">
    {{ item.value ? "Yes" : "No" }}
  </ng-container>

  <ng-container *ngSwitchCase="TTableCell.Percent">{{ $any(item.value) * 100 }}%</ng-container>

  <ng-container *ngSwitchCase="TTableCell.Custom">
    <ng-container *ngTemplateOutlet="customTemplate; context: { $implicit: item.data, id }"></ng-container>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <div class="th-table-cell__default">
      {{ item.value }}
    </div>
  </ng-container>
</div>
