import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CrudApiBaseService } from '@core/api/crud-api.base';
import { TDocumentType } from '@core/enums/document-type';
import { Files, ITechPortalFile } from '@core/interfaces/claims/files.interface';
import { IDataSearch } from '@core/interfaces/data-search.interface';
import {
  IServerTechWorkOrder,
  IServerWorkOrderDetail,
  ITechReport,
} from '@core/interfaces/tech-work-orders/tech-work-orders.interface';
import { TechWorkOrderSearchRequest } from '@core/models/search/search-request.model';

@Injectable({ providedIn: 'root' })
export class TechWorkOrdersApi extends CrudApiBaseService<IServerTechWorkOrder> {
  endpoint = 'workOrder';

  all(searchRequest: TechWorkOrderSearchRequest, queryParams?: {tileId?: string}) {
    // if (searchRequest.fromDate) {
    //   searchRequest.fromDate = parseZone(searchRequest.fromDate).startOf('day').toISOString();
    // }
    //
    // if (searchRequest.toDate) {
    //   searchRequest.toDate = parseZone(searchRequest.toDate).endOf('day').toISOString();
    // }
    return this.httpClient.post<IDataSearch<IServerTechWorkOrder>>(this.getUrl('all'), searchRequest, {
      params: queryParams,
    });
  }

  getIds(searchRequest: TechWorkOrderSearchRequest) {
    return this.httpClient.post<number[]>(this.getUrl('all'), searchRequest);
  }

  getWorkOrderDetails(id: string | number) {
    return this.httpClient.get<IServerWorkOrderDetail>(this.getUrl(`${id}`));
  }

  getWorkOrderTechReport(id: string | number) {
    return this.httpClient.get<ITechReport>(this.getUrl(`${id}/report`));
  }

  updateWorkOrderTechReport(techReport: ITechReport) {
    return this.httpClient.put<ITechReport>(this.getUrl('report'), techReport);
  }

  getFiles(id: number, docType: TDocumentType = null) {
    const params: {docType?: TDocumentType} = {};

    if (docType) {
      params.docType = docType;
    }
    return this.httpClient.get<Omit<Files, 'index'>[]>(this.getUrl(`${id}/files`), {
      params,
    }).pipe(
      map(files => files.map((file, index) => ({
        ...file,
        index,
      }))),
    );
  }

  getTechReportFiles(id: number, docType: TDocumentType = null) {
    return this.httpClient.get<Omit<ITechPortalFile, 'index'>[]>(this.getUrl(`${id}/files`), {
      params: {
        docType,
      },
    });
  }

  deleteTechReportFile(workOrderId: number, fileId: number) {
    return this.httpClient.delete(this.getUrl(`${workOrderId}/reportphotos/${fileId}`));
  }

  acceptMany(workOrderIds: number[]) {
    return this.httpClient.post<any>(this.getUrl('acceptmany'), workOrderIds);
  }

  rejectMany(workOrderIds: {workOrderId: number; rejectionReason: string}[]) {
    return this.httpClient.post<any>(this.getUrl('rejectmany'), workOrderIds);
  }

  scheduleMany(workOrderIds: {workOrderId: number; scheduleDate: string}[]) {
    return this.httpClient.post<any>(this.getUrl('schedulemany'), workOrderIds);
  }

  rescheduleMany(workOrderIds: {workOrderId: number; rescheduleDate: string; rescheduleReason: string}[]) {
    return this.httpClient.post<any>(this.getUrl('reschedulemany'), workOrderIds);
  }

  allWorkOrderStatus(searchRequest: TechWorkOrderSearchRequest) {
    return this.httpClient.post<any[]>(this.getUrl('allworkorderstatus'), searchRequest);
  }

  exportWorkOrders(request: any): Observable<HttpResponse<Blob>> {
    return this.httpClient.post(this.getUrl('export'), request, { observe: 'response', responseType: 'blob' });
  }

  uploadReportProductFiles(
    workOrderId: number,
    crmServiceActionProductId: string,
    productFiles: {
      productPhotos?: Files[];
      tagPhotos?: Files[];
      damagePhotos?: Files[];
    } = {},
  ) {
    const formData = this.groupedFilesToFormData(productFiles);
    return this.httpClient.post(this.getUrl(`${workOrderId}/reportphotos/${crmServiceActionProductId}`), formData);
  }

  uploadServiceFiles(
    workOrderId: number,
    serviceFiles: {
      servicePhotos?: Files[];
    },
  ) {
    const formData = this.groupedFilesToFormData(serviceFiles);
    return this.httpClient.post(this.getUrl(`${workOrderId}/reportphotos`), formData);
  }

  private groupedFilesToFormData(groupedFiles: {[key: string]: Files[]}) {
    const formData = new FormData();
    const fileSectionKeys = Object.keys(groupedFiles);
    fileSectionKeys.forEach(fileSectionKey => {
      const sectionFiles = groupedFiles[fileSectionKey] as Files[];
      sectionFiles.forEach(sectionFile => {
        formData.append(fileSectionKey, sectionFile.originalFile);
      });
    });

    return formData;
  }
}
