import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment-timezone';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { catchError, finalize, map, skip, take } from 'rxjs/operators';

import { APP_CONFIG } from '@core/constants/app-config.constants';
import { TRetailerAction } from '@core/enums/claim/retailer-action.enum';
import { ClaimType } from '@core/enums/claim-type.enum';
import { Feature } from '@core/enums/feature.enum';
import { TPortalType } from '@core/enums/portal-type.enum';
import { TReplacementType } from '@core/enums/replacement-type.enum';
import { TServiceActionTileStage } from '@core/enums/service-action/service-action-tile-stage.enum';
import { TServiceActionType } from '@core/enums/service-action/service-action-type.enum';
import { FeatureService } from '@core/services/feature.service';
import { ServiceActionService } from '@core/services/service-action.service';
import { NotificationType } from '@shared/modules/notification/enums/notification-type.enum';
import { NotificationService } from '@shared/modules/notification/services/notification.service';

import {
  COMPOSITE_TILE_SEARCH_REQUESTS,
  RetailerTileFilter,
} from '../../../../../th-retailer/app/core/constants/tile-stage-filter.constant';
import { ServiceActionApi } from '../../api/service-action-api';
import { AppConstants } from '../../constants/app.constants';
import { TPage } from '../../enums/page.enum';
import { StorageItem } from '../../enums/storage-item.enum';
import {
  ClaimDetails,
  ClaimServiceAction,
  IClaimDetailsModel,
  IClaimNote,
  IClaimServiceActionModel,
  IRetailerAction,
} from '../../interfaces/claims/claimDetails.interface';
import { Files } from '../../interfaces/claims/files.interface';
import { OtherClaims } from '../../interfaces/claims/otherClaims.interface';
import { ISession } from '../../interfaces/session/session-data.interface';
import { HubEvent, THubMethods } from '../../models/hub-event.model';
import { ClaimService } from '../../services/claim.service';
import { MainHub } from '../../services/hubs/main.hub';
import { AppStorage } from '../../utils/storage';
import { BaseStore } from '../_base/base.store';
import { ClaimsStore } from '../claims/claims.store';
import { StoreState } from './claim-item.state';
import IStoreState = StoreState.IStoreState;
import initialState = StoreState.initialState;
import { MjcTileFilter } from '../../../../../th-mjc/app/core/constants/mjc-tile-stage-filter.constant';
import { SERVICER_TYPE_TO_TITLE } from '../../../../../th-mjc/app/core/enums/servicing-type.enum';

const serviceActionTypeToAction = {
  [TServiceActionType.CashSettlement]: TRetailerAction.ProcessCashSettlement,
  [TServiceActionType.Replacement]: TRetailerAction.ProcessReplacement,
  [TServiceActionType.Complaint]: TRetailerAction.ProcessComplaint,
  [TServiceActionType.TechWorkOrder]: TRetailerAction.ProcessTechWorkOrder,
};

const serviceActionTypeFeatures = {
  [TServiceActionType.CashSettlement]: [Feature.AcknowledgeMyServiceSettlement],
  [TServiceActionType.Replacement]: [Feature.AcknowledgeMyServiceExchange],
  [TServiceActionType.Complaint]: [Feature.AcknowledgeMyServiceComplaint],
  [TServiceActionType.TechWorkOrder]: [Feature.ViewTechReport],
  [TServiceActionType.PartOrder]: [Feature.AcknowledgeMyServiceParts],
};

const countableServiceActions = [
  TServiceActionType.CashSettlement,
  TServiceActionType.Replacement,
  TServiceActionType.Complaint,
];

@Injectable({
  providedIn: 'root',
})
export class ClaimItemStore extends BaseStore<IStoreState> {
  protected session: ISession = AppStorage.get(StorageItem.Session);
  apiError$ = new Subject<void>();
  newCurrentClaim$ = new Subject<number>();
  detailsOpened$ = new BehaviorSubject<boolean>(false);
  features = Feature;
  appConfig = inject(APP_CONFIG);

  constructor(
    private readonly claimService: ClaimService,
    private readonly serviceActionApi: ServiceActionApi,
    private readonly notification: NotificationService,
    private readonly router: Router,
    private readonly mainHub: MainHub,
    private readonly claimsStore: ClaimsStore,
    public featureService: FeatureService,
  ) {
    super(initialState);
  }

  init() {
    this.mainHub.baseHub.eventBus$.subscribe((event: HubEvent) => {
      const currentId = this.get('currentId');
      const files = this.get('files');
      switch (event.name) {
        case THubMethods.DocsUploaded:
          if (event.data.tenantId === this.session.tenantId && event.data.claimId === +currentId) {
            if (event.data.docs) {
              event.data.docs.forEach(doc => {
                const index = files.findIndex(file => file.id === doc.id);
                if (index !== -1) {
                  files[index] = { ...doc };
                } else {
                  files.push(doc);
                }
              });
            }
            files.sort((a, b) => {
              if (new Date(a.dateCreated).getTime() > new Date(b.dateCreated).getTime()) {
                return -1;
              }
              if (new Date(a.dateCreated).getTime() < new Date(b.dateCreated).getTime()) {
                return 1;
              }
              return 0;
            });
            this.updateState({
              files: [...files],
            });
            this.createTimelineData();
          }
          break;
        case THubMethods.ClaimStatusChange:
          if (event.data.tenantId === this.session.tenantId && event.data.claimId === +currentId) {
            const claim = this.get('claim');
            if (claim) {
              claim.claimInfo.claimStage = event.data.stage;
              claim.claimInfo.claimStatus = event.data.status;
            }
            this.updateState({ claim });
          }
          break;
      }
    });
  }

  destroy() {
    this.detailsOpened$.next(false);
    this.updateState(initialState);
    this.updateState({ previousClaimsIds: [] });
    const routeUrl = `/${TPage.App}/${TPage.Claims}`;
    if (window.location.pathname === routeUrl) {
      this.router.navigate([routeUrl]);
    }
  }

  getClaimById() {
    this.updateState({
      loading: true,
    });
    const currentId = this.get('currentId');
    this.claimService
      .getClaimDetails(currentId)
      .pipe(
        finalize(() => {
          this.updateState({ loading: false });
        }),
      )
      .subscribe(
        response => {
          Object.keys(response.communication).forEach(key => {
            response.communication[key].forEach(item => {
              item.claimId = response.claimInfo.id;
            });
          });
          const claim = this.claimToViewModel(response);
          this.updateState({
            claim,
          });
          if (this.appConfig.portalType === TPortalType.MJC) {
            claim.serviceActions.forEach(serviceAction => {
              this._setShippingLabel(serviceAction, claim);
            });
          }
          this.getOtherClaims(response.consumerPlanDetailed.consumerId);
          this.getFiles();
        },
        error => {
          this.apiError$.next();
        },
      );
  }

  claimToViewModel(claimDetails: ClaimDetails): IClaimDetailsModel {
    return {
      ...claimDetails,
      serviceActions: this.returnServiceActionsModel(claimDetails),
    };
  }

  private returnServiceActionsModel(claimDetails: ClaimDetails): IClaimServiceActionModel[] {
    return claimDetails.serviceActions.map(serviceAction => {
      const actions: IRetailerAction[] = [];
      let actionsCount = 0;

      if (claimDetails.claimInfo.claimType === ClaimType.MS) {
        if (ServiceActionService.returnButtonText(serviceAction)) {
          actions.push({
            title: ServiceActionService.returnButtonText(serviceAction),
            titleSecondLine: '',
            action: serviceActionTypeToAction[serviceAction.serviceActionType],
            features: serviceActionTypeFeatures[serviceAction.serviceActionType] || [],
          });

          if (countableServiceActions.includes(serviceAction.serviceActionType)) {
            actionsCount++;
          }
        }

        if (ServiceActionService.isInvoice(serviceAction)) {
          actions.push({
            title: 'Invoice',
            titleSecondLine: '',
            action: TRetailerAction.Invoice,
            features: [],
          });
        }
      } else {
        if (
          RetailerTileFilter.matchServiceActionWithSearchRequest(
            serviceAction,
            COMPOSITE_TILE_SEARCH_REQUESTS[
              RetailerTileFilter.getActionTypeTileStageKey(TServiceActionType.PartOrder, TServiceActionTileStage.OrderParts)
            ],
          )
        ) {
          actions.push({
            title: 'View Authorization &',
            titleSecondLine: 'Order Parts',
            action: TRetailerAction.ViewAuthorizationAndOrderParts,
            features: [Feature.AcknowledgePPOrderParts],
          });
          actionsCount++;
        } else if (
          RetailerTileFilter.matchServiceActionWithSearchRequest(
            serviceAction,
            COMPOSITE_TILE_SEARCH_REQUESTS[
              RetailerTileFilter.getActionTypeTileStageKey(
                TServiceActionType.PartOrder,
                TServiceActionTileStage.EnterDelivery,
              )
            ],
          )
        ) {
          actions.push({
            title: 'Confirm Delivery &',
            titleSecondLine: 'Request Payment',
            action: TRetailerAction.PartOrderEnterDeliveryAndPaymentRequest,
            features: [Feature.AcknowledgePPRequestPaymentPartOrders],
          });
          actionsCount++;
        } else if (
          RetailerTileFilter.matchServiceActionWithSearchRequest(
            serviceAction,
            COMPOSITE_TILE_SEARCH_REQUESTS[
              RetailerTileFilter.getActionTypeTileStageKey(
                TServiceActionType.Replacement,
                TServiceActionTileStage.OrderReplacements,
              )
            ],
          )
        ) {
          actions.push({
            title: 'View Authorization &',
            titleSecondLine: 'Order Replacement',
            action: TRetailerAction.ConfirmAvailability,
            features: [Feature.AcknowledgeConfirmAvailability],
          });
          actionsCount++;
        } else if (
          RetailerTileFilter.matchServiceActionWithSearchRequest(
            serviceAction,
            COMPOSITE_TILE_SEARCH_REQUESTS[
              RetailerTileFilter.getActionTypeTileStageKey(
                TServiceActionType.Replacement,
                TServiceActionTileStage.EnterDelivery,
              )
            ],
          )
        ) {
          actions.push({
            title: 'Confirm Delivery &',
            titleSecondLine: 'Request Payment',
            // TODO: can crash if retailer is null
            action: this.getReplacementAction(serviceAction, claimDetails.retailer.hasEasyReplacement),
            features: [Feature.AcknowledgePPRequestPaymentReplacements],
          });
          actionsCount++;
        }
      }

      return {
        ...serviceAction,
        actionsCount,
        actions,
      };
    });
  }

  private getReplacementAction(serviceAction: ClaimServiceAction, hasEasyReplacement: boolean) {
    if (serviceAction.serviceActionReplacementType === TReplacementType.EvenExchange) {
      return hasEasyReplacement ? TRetailerAction.EZReplacementEvenExchange : TRetailerAction.ReplacementEvenExchange;
    } else if (serviceAction.serviceActionReplacementType === TReplacementType.Reselection) {
      return hasEasyReplacement ? TRetailerAction.EZReplacementReselection : TRetailerAction.ReplacementReselection;
    }
  }

  getSmsHistoryForClaim() {
    if (this.featureService.allowedMany([this.features.ViewSmsHistory])) {
      this.updateState({
        smsHistoryLoading: true,
      });
      const currentId = this.get('currentId');
      this.claimService
        .getSmsHistoryForClaim(currentId)
        .pipe(
          finalize(() => {
            this.updateState({ smsHistoryLoading: false });
          }),
        )
        .subscribe(smsHistoryList => {
          this.updateState({
            smsHistoryList,
          });
        });
    }
  }

  getFiles() {
    this.updateState({
      files: [],
    });
    const currentId = this.get('currentId');
    if (currentId) {
      this.updateState({
        filesLoading: true,
        documentsFilterLoading: true,
      });
      this.claimService
        .getFiles(currentId)
        .pipe(
          finalize(() => {
            this.updateState({ documentsFilterLoading: false });
          }),
        )
        .subscribe(
          (files: Files[]) => {
            this.updateState({
              files: [...files],
            });
            if (!files.length) {
              this.updateState({
                filesLoading: false,
              });
            }
            this.createTimelineData();
          },
          () => {
            this.updateState({
              filesLoading: false,
            });
          },
        );
    }
  }

  setCurrentId(id: number) {
    this.updateState({ currentId: id });
    this.getClaimById();
    this.detailsOpened$.next(true);
  }

  navigateToClaim(claimId: number) {
    if (window.location.pathname === this.getRouteUrl()) {
      this.router.navigate([this.getRouteUrl()], { queryParams: { id: claimId } });
    }
  }

  uploadFiles(filesToUpload: Files[]) {
    this.updateState({ loading: true });
    const currentId = this.get('currentId');
    return this.claimService.uploadFiles(filesToUpload, currentId).pipe(
      finalize(() => {
        this.updateState({ loading: false });
      }),
    );
  }

  changeClaimDetailsPage(page: number, claimIndex: number) {
    this.updateState({ loading: true });
    this.claimsStore.updatePagination(page);
    this.claimsStore
      .select$('claims')
      .pipe(skip(1), take(1))
      .subscribe(claims => {
        this.setCurrentId(claims[claimIndex].id);
      });
  }

  allFilesLoaded() {
    this.updateState({ filesLoading: false });
  }

  getRouteUrl(): string {
    return '/app/claims';
  }

  createTimelineData() {
    const claim = this.get('claim');
    const files = JSON.parse(JSON.stringify(this.get('files')));
    if (!claim) {
      return;
    }
    const communication = claim.communication;
    const timeline = {};
    // building object with dates keys.
    for (const key in communication) {
      if (communication.hasOwnProperty(key)) {
        communication[key].forEach(item => {
          const dateKey = this.getDateKey(item.createDate);
          if (!timeline.hasOwnProperty(dateKey)) {
            timeline[dateKey] = [];
          }
          Object.assign(item, {
            type: AppConstants.claimCommunicationActivityType[item.activityTypeCode],
            title: AppConstants.claimCommunicationActivityType[item.activityTypeCode],
            created: item.createDate,
          });
          timeline[dateKey].push(item);
        });
      }
    }
    claim.notes.forEach(note => {
      const dateKey = this.getDateKey(note.createdOn);
      if (!timeline.hasOwnProperty(dateKey)) {
        timeline[dateKey] = [];
      }
      Object.assign(note, {
        type: 'Note',
        title: 'Note',
        created: note.createdOn,
      });
      timeline[dateKey].push(note);
    });
    claim.serviceActions.forEach(serviceAction => {
      const dateKey = this.getDateKey(serviceAction.openDate);
      if (!timeline.hasOwnProperty(dateKey)) {
        timeline[dateKey] = [];
      }
      Object.assign(serviceAction, {
        type: 'Service Taken',
        title: 'Service Taken',
        created: serviceAction.openDate,
      });
      timeline[dateKey].push(serviceAction);
    });
    files.forEach((file, index) => {
      const dateKey = this.getDateKey(file.dateCreated);
      if (!timeline.hasOwnProperty(dateKey)) {
        timeline[dateKey] = [];
      }
      Object.assign(file, {
        type: 'Files',
        title: 'PHOTOS/DOCUMENTS',
        created: file.dateCreated,
        index,
      });
      timeline[dateKey].push(file);
    });
    // order elements in the item of the day items list in descending order.
    for (const key in timeline) {
      if (timeline.hasOwnProperty(key)) {
        timeline[key] = timeline[key].sort((a, b) => {
          if (new Date(a.dateCreated).getTime() > new Date(b.dateCreated).getTime()) {
            return -1;
          }
          if (a.dateCreated < b.dateCreated) {
            return 1;
          }
          return 0;
        });
      }
    }
    // Order days items in descending order.
    const ordered = [];
    Object.keys(timeline)
      .sort((a, b) => {
        if (new Date(a).getTime() < new Date(b).getTime()) {
          return -1;
        }
        if (new Date(a).getTime() > new Date(b).getTime()) {
          return 1;
        }
        return 0;
      })
      .forEach(key => {
        ordered.push({ date: key, items: timeline[key] });
      });
    // assigning a files items as item with list of files spread trough timeline from
    // beginning of the day to the next non file item, and between non file items, and till the end of the day.
    for (let i = 0; i < ordered.length; i++) {
      const newItems = [];
      let newFileItems = [];
      for (let j = 0; j < ordered[i].items.length; j++) {
        if (ordered[i].items[j].type !== 'Files') {
          if (newFileItems.length) {
            newItems.push(this.createFileItem(newFileItems));
          }
          newFileItems = [];
          newItems.push(ordered[i].items[j]);
        } else {
          newFileItems.push(ordered[i].items[j]);
        }
        if (j === ordered[i].items.length - 1) {
          if (newFileItems.length) {
            newItems.push(this.createFileItem(newFileItems));
          }
        }
      }
      ordered[i].items = newItems;
    }
    this.updateState({ timeline: ordered });
  }

  private createFileItem(newFileItems: any[]) {
    newFileItems = newFileItems.sort((a, b) => {
      if (new Date(a.dateCreated).getTime() > new Date(b.dateCreated).getTime()) {
        return -1;
      }
      if (new Date(a.dateCreated).getTime() < new Date(b.dateCreated).getTime()) {
        return 1;
      }
      return 0;
    });
    return {
      type: 'Files',
      title: 'PHOTOS/DOCUMENTS',
      created: newFileItems[0].created,
      files: newFileItems.map((fileItem, index) => ({
        ...fileItem,
        index,
      })),
    };
  }

  resetForRetailerReview() {
    const claim = this.get('claim');
    this.updateState({ loading: true });
    this.claimService
      .resetForRetailerReview(claim.claimInfo.id)
      .pipe(
        finalize(() => {
          this.updateState({ loading: false });
        }),
      )
      .subscribe(() => {
        claim.claimInfo.isFlaggedForRetailerReview = false;
        claim.claimInfo.flagCleared = true;
        this.updateState({ claim });
        this.claimsStore.clearFlag(claim.claimInfo.id);
      });
  }

  saveNote(note: string) {
    this.updateState({ loading: true });
    const currentId = this.get('currentId');
    return this.claimService.saveNote(currentId, note).pipe(
      map(note => {
        this.pushNote(note);
        return note;
      }),
      catchError(error => {
        if (error.status === 400) {
          this.notification.next({
            message: error.error.errors.Note[0],
            type: NotificationType.Error,
            duration: 6000,
          });
        } else {
          this.notification.next({
            message: 'API error, try again later.',
            type: NotificationType.Error,
          });
        }
        return throwError(error);
      }),
      finalize(() => {
        this.updateState({ loading: false });
      }),
    );
  }

  openNewClaim(id: number) {
    this.newCurrentClaim$.next(id);
  }

  pushToPreviousClaim(id: number) {
    const previousClaimsIds = this.get('previousClaimsIds');
    const currentId = this.get('currentId');
    previousClaimsIds.push(currentId);
    this.updateState({ previousClaimsIds });
    this.setCurrentId(id);
  }

  returnToCurrentClaim() {
    const previousClaimsIds = this.get('previousClaimsIds');
    if (previousClaimsIds.length) {
      const currentId = previousClaimsIds[previousClaimsIds.length - 1];
      previousClaimsIds.splice(-1, 1);
      this.updateState({ previousClaimsIds });
      this.setCurrentId(currentId);
    }
  }

  getOtherClaims(consumerId: number) {
    const claim = this.get('claim');
    this.claimService.getOtherClaims(consumerId, claim.claimInfo.id).subscribe((otherClaimsResponse: OtherClaims[]) => {
      const otherClaims = otherClaimsResponse.map(otherClaim => {
        const productInformation = otherClaim.productNames;
        const products = productInformation.length
          ? productInformation.length === 1
            ? `${productInformation[0]}`
            : `${productInformation[0]} (and ${productInformation.length - 1} other product${
              productInformation.length - 1 > 1 ? 's' : ''
            })`
          : 'No Products';
        Object.assign(otherClaim, { products });
        return otherClaim;
      });

      this.updateState({ otherClaims });
    });
  }

  processComplaint(id: number, note: string) {
    this.updateState({ loading: true });
    return this.serviceActionApi.processComplaint(id, note).pipe(
      map(response => {
        this.processUpdateServiceActionWithNotes(response);
        return response;
      }),
      finalize(() => {
        this.updateState({ loading: false });
      }),
    );
  }

  processExchange(id: number, note: string) {
    this.updateState({ loading: true });
    return this.serviceActionApi.processExchanges(id, note).pipe(
      map(response => {
        this.processUpdateServiceActionWithNotes(response);
        return response;
      }),
      finalize(() => {
        this.updateState({ loading: false });
      }),
    );
  }

  processCashSettlement(id: number, note: string) {
    this.updateState({ loading: true });
    return this.serviceActionApi.processSettlement(id, note).pipe(
      map(response => {
        this.processUpdateServiceActionWithNotes(response);
        return response;
      }),
      finalize(() => {
        this.updateState({ loading: false });
      }),
    );
  }

  private processUpdateServiceActionWithNotes(response: any) {
    if (response.claimNote) {
      this.pushNote(response.claimNote);
    }
    this.updateServiceAction();
  }

  updateServiceAction() {
    this.getClaimById();
    this.notification.next({
      message: 'Retailer action successfully completed.',
      type: NotificationType.Success,
      duration: 6000,
    });
  }

  private getDateKey(createDate: Date | string) {
    const date = moment(createDate).tz(this.session.timeZone.ianaName);
    return `${date.format('M/D/Y')}`;
  }

  private pushNote(note: IClaimNote) {
    const claim = this.get('claim');
    claim.notes.push(note);
    this.updateState({ claim });
    this.createTimelineData();
  }

  private _setShippingLabel(serviceAction: IClaimServiceActionModel, claim: IClaimDetailsModel): void {
    const {
      servicerTypes,
      serviceActionType,
      serviceActionStatus,
    } = MjcTileFilter.getRepairsStageReadyToShipFilters().serviceActionFilters[0];

    if (serviceAction.serviceActionType !== serviceActionType || serviceAction.serviceActionStatus !== serviceActionStatus) {
      return;
    }

    const repairActionsByServiceActionId = claim.repairActions.filter(
      repairAction => repairAction.serviceActionId === serviceAction.id,
    );

    const repairActionMatchFilters = repairActionsByServiceActionId.some(
      repairAction => servicerTypes.map(
        servicerType => SERVICER_TYPE_TO_TITLE[servicerType],
      ).includes(repairAction.repairServicingType),
    );

    if (!repairActionMatchFilters) {
      return;
    }

    let shippingLabelUrl = null;
    let regenerateShippingLabel = false;

    repairActionsByServiceActionId.forEach(repairAction => {
      if (shippingLabelUrl) {
        return;
      }
      if (repairAction.shippingLabelUrl && !repairAction.regenerateShippingLabel) {
        shippingLabelUrl = repairAction.shippingLabelUrl;
        regenerateShippingLabel = false;
      } else {
        regenerateShippingLabel = true;
      }
    });

    const disableShippingLabel = repairActionsByServiceActionId.every(repairAction => {
      const noShipToAddress = !repairAction.shipToAddress
        || !repairAction.shipToAddress.streetAddress1
        || !repairAction.shipToAddress.name;
      const noShipFromAddress = !repairAction.shipFromAddress
        || !repairAction.shipFromAddress.streetAddress1
        || !repairAction.shipFromAddress.name;
      const noReturnToAddress = !repairAction.returnToAddress
        || !repairAction.returnToAddress.streetAddress1
        || !repairAction.returnToAddress.name;
      return noShipToAddress || noShipFromAddress || noReturnToAddress;
    });

    this._updateServiceActionById(serviceAction.id, {
      claimNumber: claim.claimInfo.claimNumber,
      showShippingLabel: true,
    });

    if (disableShippingLabel) {
      this._updateServiceActionById(serviceAction.id, {
        disableShippingLabelReason: 'No shipping address found',
      });
      return;
    }

    if (regenerateShippingLabel) {
      this._updateServiceActionById(serviceAction.id, {
        shippingLabelLoading: true,
      });
      this.serviceActionApi.getShippingLabel(serviceAction.id).pipe(
        finalize(() => {
          this._updateServiceActionById(serviceAction.id, {
            shippingLabelLoading: false,
          });
        }),
      ).subscribe(response => {
        this._updateServiceActionById(serviceAction.id, {
          shippingLabelUrl: response.href,
        });
      });
    } else {
      this._updateServiceActionById(serviceAction.id, {
        shippingLabelUrl,
      });
    }
  }

  private _updateServiceActionById(serviceActionId: number, updatedServiceAction: Partial<ClaimServiceAction>): void {
    const claim = this.get('claim');
    this.updateState({
      claim: {
        ...claim,
        serviceActions: claim.serviceActions.map(serviceAction => {
          if (serviceActionId === serviceAction.id) {
            return {
              ...serviceAction,
              ...updatedServiceAction,
            };
          } else {
            return serviceAction;
          }
        }),
      },
    });
  }
}
