import { AsyncPipe, CurrencyPipe, NgForOf, NgIf } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { RxState } from '@rx-angular/state';
import { RxFor } from '@rx-angular/template/for';
import { LetDirective } from '@rx-angular/template/let';
import { ClaimsPageService } from '@th-common-retailer/shared/pages/claims/claims-page.service';
import { of, Subject } from 'rxjs';
import { filter, finalize, map, skip, switchMap, takeUntil } from 'rxjs/operators';

import { AnchorNavigationClass } from '@core/classes/anchor-navigation';
import { DestroyableComponent } from '@core/classes/destroyable-component';
import { CLAIM_FILES_DROP_AREA, RECEIPT_FILES_DROP_AREA } from '@core/constants/app.constants';
import { APP_CONFIG } from '@core/constants/app-config.constants';
import { ClaimDetailsType } from '@core/enums/claim/claim-details-type.enum';
import { ClaimType } from '@core/enums/claim-type.enum';
import { Feature } from '@core/enums/feature.enum';
import { TPortalType } from '@core/enums/portal-type.enum';
import { AnchorNavigation } from '@core/interfaces/anchor-navigation/anchor-navigation.interface';
import { ClaimDetails, IServerRepairAction } from '@core/interfaces/claims/claimDetails.interface';
import { Files, IDropArea } from '@core/interfaces/claims/files.interface';
import { DialogService } from '@core/services/dialog.service';
import { SideDialogStateService } from '@core/services/dialog/side-dialog-state.service';
import { FeatureService } from '@core/services/feature.service';
import { ClaimItemStore } from '@core/store/claim-item/claim-item.store';
import { ClaimsStore } from '@core/store/claims/claims.store';
import { validateArrayMaxFilesSize } from '@shared/common/form-validators';
import { ConsumerInformationComponent } from '@shared/components/consumer-information/consumer-information.component';
import {
  SideDialogContainerComponent,
} from '@shared/components/dialogs/side-dialog-container/side-dialog-container.component';
import { SideDialogContentComponent } from '@shared/components/dialogs/side-dialog-content/side-dialog-content.component';
import { SideDialogNavComponent } from '@shared/components/dialogs/side-dialog-nav/side-dialog-nav.component';
import { SwitchComponent } from '@shared/components/switch/switch.component';
import { LoadingButtonDirective } from '@shared/directives/loading-button.directive';
import { ThFormFieldComponent } from '@shared/form/form-field/form-field.component';
import { ThFormFieldBlockComponent } from '@shared/form/th-form-field-block/th-form-field-block.component';
import { AccessControlModule } from '@shared/modules/access-control/access-control.module';
import { ClaimDetailsCoreModule } from '@shared/modules/claim-details/claim-details-core.module';
import { BaseClaimDetailsTablesConfig } from '@shared/modules/claim-details/classes/base-claim-details-tables-config';
import { DealerClaimDetailsTablesConfig } from '@shared/modules/claim-details/classes/dealer-claim-details-tables-config';
import {
  ClaimDetailsHeaderComponent,
} from '@shared/modules/claim-details/components/claim-details-header/claim-details-header.component';
import {
  CollapsibleBlockComponent,
} from '@shared/modules/collapsible/components/collapsible-block/collapsible-block.component';
import {
  CollapsibleDataBlockComponent,
} from '@shared/modules/collapsible/components/collapsible-data-block/collapsible-data-block.component';
import { DocumentsFilterComponent } from '@shared/modules/files/components/documents-filter/documents-filter.component';
import { FilesPreviewNewComponent } from '@shared/modules/files/components/files-preview-new/files-preview-new.component';
import {
  GroupedClaimFilesComponent,
} from '@shared/modules/files/components/grouped-claim-files/grouped-claim-files.component';
import { GridModule } from '@shared/modules/grid/grid.module';
import { AnchorNavComponent } from '@shared/modules/navigation/components/anchor-nav/anchor-nav.component';
import { NavigationModule } from '@shared/modules/navigation/navigation.module';
import { NotificationType } from '@shared/modules/notification/enums/notification-type.enum';
import { NotificationService } from '@shared/modules/notification/services/notification.service';
import {
  ItemDetailsPaginationComponent,
} from '@shared/modules/side-dialog/components/item-details-pagination/item-details-pagination.component';
import {
  SideDialogHeaderComponent,
} from '@shared/modules/side-dialog/components/side-dialog-header/side-dialog-header.component';
import {
  SideDialogLoaderComponent,
} from '@shared/modules/side-dialog/components/side-dialog-loader/side-dialog-loader.component';
import { PipesModule } from '@shared/pipes/pipes.module';
import { CustomOverlayScrollbarDirective } from '@shared/standalone/custom-overlay-scrollbar.directive';
import {
  FormFilesDropAreaComponent,
} from '@shared/standalone/file-controls/form-files-drop-area/form-files-drop-area.component';
import { FilesTotalSizeErrorComponent } from '@shared/standalone/files-total-size-error/files-total-size-error.component';
import { LabelValueItemComponent } from '@shared/standalone/label-value-item/label-value-item.component';
import { SideDialogAnimationComponent } from '@shared/standalone/side-dialog-animation/side-dialog-animation.component';

import { RepairActionsTableComponent } from './components/repair-actions-table/repair-actions-table.component';
import { ServiceActionGridComponent } from './containers/service-action-grid/service-action-grid.component';
import { TimelineComponent } from './containers/timeline/timeline.component';
import {
  ViewMessageHistoryDialogComponent,
} from './containers/view-message-history-dialog/view-message-history-dialog.component';

interface IClaimDetailsState {
  currentClaimIndex: number;
  claim: ClaimDetails;
  singleView: boolean;
  serviceActionsCount: number;
  dropAreasList: IDropArea[];
  isVisible: boolean;
  loading: boolean;
  showClaimReviewBtn: boolean;
  allowClaimReview: boolean;
  markCompleteLoading: boolean;
  showCompleteRepairBtn: boolean;
  showDocumentFilter: boolean;
  showRepairActions: boolean;
}

@Component({
  selector: 'app-details-component',
  standalone: true,
  templateUrl: './claim-details.component.html',
  styleUrls: ['./claim-details.component.scss'],
  providers: [RxState, SideDialogStateService],
  imports: [
    AccessControlModule,
    LoadingButtonDirective,
    AsyncPipe,
    MatButtonModule,
    NgIf,
    GridModule,
    MatLegacyProgressSpinnerModule,
    FormFilesDropAreaComponent,
    RxFor,
    FilesTotalSizeErrorComponent,
    ClaimDetailsCoreModule,
    MatIconModule,
    SwitchComponent,
    FormsModule,
    NavigationModule,
    CustomOverlayScrollbarDirective,
    ConsumerInformationComponent,
    CollapsibleBlockComponent,
    CollapsibleDataBlockComponent,
    TimelineComponent,
    PipesModule,
    MatLegacyInputModule,
    ReactiveFormsModule,
    ClaimDetailsHeaderComponent,
    ServiceActionGridComponent,
    AnchorNavComponent,
    SideDialogAnimationComponent,
    LetDirective,
    SideDialogHeaderComponent,
    ItemDetailsPaginationComponent,
    SideDialogLoaderComponent,
    GroupedClaimFilesComponent,
    FilesPreviewNewComponent,
    DocumentsFilterComponent,
    RepairActionsTableComponent,
    SideDialogContainerComponent,
    SideDialogNavComponent,
    SideDialogContentComponent,
    NgForOf,
    LabelValueItemComponent,
    ThFormFieldBlockComponent,
    ThFormFieldComponent,
    CurrencyPipe,
  ],
})
export class ClaimDetailsComponent extends DestroyableComponent implements OnInit, OnDestroy {
  vm$ = this.state.select();
  claimsPagination$ = this.claimsStore.select$('pagination').pipe(
    switchMap(pagination =>
      this.claimsStore.select$('searchRequest', 'pageSize').pipe(
        map(pageSize => ({
          ...pagination,
          pageSize,
        })),
      ),
    ),
  );
  otherClaims$ = this.claimItemStore.select$('otherClaims');
  otherClaimsLength$ = this.claimItemStore.select$('otherClaims', 'length');
  previousClaimsIdsLength$ = this.claimItemStore.select$('previousClaimsIds', 'length');
  documentsFilterLoading$ = this.claimItemStore.select$('documentsFilterLoading');
  files$ = this.claimItemStore.select$('files');
  filesLength$ = this.claimItemStore.select$('files', 'length');
  filesLoading$ = this.claimItemStore.select$('filesLoading');
  smsHistoryLoading$ = this.claimItemStore.select$('smsHistoryLoading');
  serviceActionsCount$ = this.state.select('serviceActionsCount');
  dropAreasList$ = this.state.select('dropAreasList');
  dropAreasListTwoCol$ = this.state.select('dropAreasList', 'length').pipe(map(length => length === 2));
  dropAreasListThreeCol$ = this.state.select('dropAreasList', 'length').pipe(map(length => length === 3));
  componentDestroyed$: Subject<boolean> = new Subject<boolean>();
  dropAreasCatalogue = {
    myService: [CLAIM_FILES_DROP_AREA],
    protectionPlan: [RECEIPT_FILES_DROP_AREA, CLAIM_FILES_DROP_AREA],
  };
  dropAreasList: IDropArea[];
  form = this.fb.group({
    note: ['', Validators.compose([Validators.required, Validators.maxLength(4000)])],
  });
  filesForm = this.fb.array<Files>([], [Validators.required, validateArrayMaxFilesSize()]);
  timelineViewToggle = false;
  timelineNavigationList = [];
  claimDetailsTypes = ClaimDetailsType;
  ClaimType = ClaimType;
  Feature = Feature;

  appConfig = inject(APP_CONFIG);

  classicNavigationList: AnchorNavigation[] = [
    { name: 'Consumer', anchor: 'CustomerInformation' },
    { name: 'Consumer Agreement', anchor: 'Warranty' },
    { name: 'Claim Incidents', anchor: 'ClaimProduct' },
    { name: 'Service Actions on Claim', anchor: 'ServicesTaken', actions: this.serviceActionsCount$ },
    {
      name: 'Repair Actions',
      anchor: 'RepairActions',
      show$: this.state.select('showRepairActions'),
    },
    { name: 'Claim Communications', anchor: 'ClaimCommunications' },
    { name: 'Claim Notes', anchor: 'Notes' },
    { name: 'Add Note', anchor: 'AddNotes', show$: of(this.featureService.allowed(Feature.AddClaimNotes)) },
    { name: 'Photos/Documents', anchor: 'Files' },
    { name: 'Add Photos/Documents', anchor: 'AddFiles', show$: of(this.featureService.allowed(Feature.UploadClaimFiles)) },
    { name: 'Other Claims', anchor: 'OtherClaims' },
  ];
  anchorNavigation = new AnchorNavigationClass({
    currentAnchor: 'CustomerInformation',
    list: this.classicNavigationList,
  });
  claimNoteColumns = DealerClaimDetailsTablesConfig.claimNoteColumns;
  claimWarrantyColumns = this.claimsPageService.claimWarrantyColumns;
  claimProductsColumns = this.claimsPageService.claimProductsColumns;
  claimCommunicationColumns = BaseClaimDetailsTablesConfig.claimCommunicationColumns;
  claimCommunicationEmailColumns = BaseClaimDetailsTablesConfig.claimCommunicationEmailColumns;
  claimCommunicationLettersColumns = BaseClaimDetailsTablesConfig.claimCommunicationLettersColumns;
  claimCommunicationPhoneCallsColumns = DealerClaimDetailsTablesConfig.claimCommunicationPhoneCallsColumns;
  otherClaimsColumns = DealerClaimDetailsTablesConfig.otherClaimsColumns;

  features = Feature;
  visibleTimelineNoData = false;

  docTypeFilter;
  dialogData = inject(MAT_DIALOG_DATA);

  protected readonly TPortalType = TPortalType;

  constructor(
    private readonly sideDialogStateService: SideDialogStateService,
    protected matDialog: MatDialog,
    public route: ActivatedRoute,
    public router: Router,
    private readonly claimItemStore: ClaimItemStore,
    private readonly fb: FormBuilder,
    public featureService: FeatureService,
    private readonly claimsStore: ClaimsStore,
    private readonly state: RxState<IClaimDetailsState>,
    private readonly dialogService: DialogService,
    private readonly claimsPageService: ClaimsPageService,
    private readonly notificationService: NotificationService,
  ) {
    super();
    this.state.set({
      singleView: this.dialogData.data.singleView,
      dropAreasList: [],
      showClaimReviewBtn: false,
      showDocumentFilter: this.appConfig.portalType !== TPortalType.MJC,
    });
    this.state.connect('isVisible', this.sideDialogStateService.isVisible$);
    this.state.connect('loading', this.claimItemStore.select$('loading'));
    this.state.connect('claim', this.claimItemStore.select$('claim'));
    this.state.connect('showCompleteRepairBtn', this.state.select('claim').pipe(
      filter(claim => !!claim),
      map(claim => this.claimsPageService.showCompleteRepairBtn(claim)),
    ));
    this.state.connect('showClaimReviewBtn', this.state.select('claim').pipe(
      filter(claim => !!claim),
      map(claim => {
        if (this.appConfig.portalType === TPortalType.MJC) {
          return claim.claimInfo.isFlaggedForRetailerReview;
        } else {
          const featureAllowed = claim.claimInfo.claimType === ClaimType.PP
            ? this.featureService.allowed(Feature.HasFppClaimsForClientReview)
            : this.featureService.allowed(Feature.HasClaimsForClientReview);
          return claim.claimInfo.isFlaggedForRetailerReview && featureAllowed;
        }
      }),
    ));
    this.state.connect('allowClaimReview', this.state.select('claim').pipe(
      filter(claim => !!claim),
      map(claim => this.appConfig.portalType === TPortalType.MJC || claim.claimInfo.claimType === ClaimType.PP
        ? true : featureService.allowed(Feature.ReviewMyServiceClaim)),
    ));
    this.state.connect(
      'currentClaimIndex',
      this.claimItemStore.select$('claim').pipe(
        filter(claimDetails => !!claimDetails),
        switchMap(claimDetails => {
          this.filesForm.clear();
          return this.claimsStore.select$('claims').pipe(
            map(claims => claims.findIndex(claim => claim.id === claimDetails.claimInfo.id)),
          );
        }),
      ),
    );

    this.state.connect(
      'serviceActionsCount',
      this.claimItemStore.select$('claim').pipe(
        filter(claim => !!claim),
        map(claim => claim.serviceActions.reduce((sum, serviceAction) => sum + serviceAction.actionsCount, 0)),
      ),
    );

    this.state.connect('showRepairActions', this.state.select('claim').pipe(
      filter(claim => !!claim),
      map(claim => this.appConfig.portalType === TPortalType.MJC && claim.repairActions && claim.repairActions.length > 0),
    ));

    this.state.connect(
      'dropAreasList',
      this.claimItemStore.select$('claim').pipe(
        filter(claimDetails => !!claimDetails),
        map(claimDetails => {
          if (claimDetails.claimInfo.claimType === ClaimType.MS) {
            return this.dropAreasCatalogue.myService;
          } else {
            return this.dropAreasCatalogue.protectionPlan;
          }
        }),
      ),
    );

    if (this.dialogData.data.singleView) {
      this.router.events
        .pipe(
          filter(e => e instanceof NavigationStart),
          takeUntil(this.componentDestroyed$),
        )
        .subscribe(event => {
          this.sideDialogStateService.close();
        });
    } else {
      this.route.queryParams.pipe(skip(1), takeUntil(this.componentDestroyed$)).subscribe(queryParams => {
        if (!queryParams.id) {
          this.sideDialogStateService.close();
        } else if (queryParams.id !== this.claimItemStore.get('currentId')) {
          this.claimItemStore.setCurrentId(+queryParams.id);
        }
      });
    }
  }

  ngOnInit(): void {
    this.claimItemStore.setCurrentId(+this.dialogData.data.claimInfo.id);

    this.claimItemStore.newCurrentClaim$.pipe(takeUntil(this.componentDestroyed$)).subscribe(claimId => {
      this.dialogService
        .canLeaveDefaultConfirmationObservable(this.form, this.filesForm.value.length > 0)
        .subscribe(canLeave => {
          if (canLeave) {
            this.clearData();
            this.claimItemStore.pushToPreviousClaim(claimId);
          } else {
            this.dialogService.canLeaveDefaultConfirmationObservable(this.form).subscribe(canLeave => {
              if (canLeave) {
                this.clearData();
                this.claimItemStore.pushToPreviousClaim(claimId);
              }
            });
          }
        });
    });

    this.claimItemStore.apiError$.subscribe(() => {
      this.sideDialogStateService.close();
    });

    this.claimItemStore.init();
    this.claimItemStore
      .select$('timeline')
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(timeline => {
        this.timelineNavigationList = [];
        if (timeline && timeline.length) {
          this.timelineNavigationList = timeline.map(time => {
            const date = new Date(time.date);
            const name = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
            return { name, anchor: `time-${time.date}` };
          });
          this.visibleTimelineNoData = true;
        }
        this.anchorNavigation.list = this.timelineViewToggle ? this.timelineNavigationList : this.classicNavigationList;
        if (this.timelineViewToggle) {
          this.timelineToggled();
        }
      });
  }

  ngOnDestroy(): void {
    this.claimItemStore.destroy();
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  onClose(): void {
    this.dialogService
      .canLeaveDefaultConfirmationObservable(this.form, this.filesForm.value.length > 0)
      .subscribe(canLeave => {
        if (canLeave) {
          this.sideDialogStateService.close();
        }
      });
  }

  setPage({ page, itemIndex }: {page: number; itemIndex: number}): void {
    this.dialogService
      .canLeaveDefaultConfirmationObservable(this.form, this.filesForm.value.length > 0)
      .subscribe(canLeave => {
        if (canLeave) {
          this.clearData();
          this.claimItemStore.changeClaimDetailsPage(page, itemIndex);
        }
      });
  }

  changeItemIndex(itemIndex: number): void {
    this.dialogService
      .canLeaveDefaultConfirmationObservable(this.form, this.filesForm.value.length > 0)
      .subscribe(canLeave => {
        if (canLeave) {
          const claims = this.claimsStore.get('claims');
          this.claimItemStore.navigateToClaim(claims[itemIndex].id);
          this.form.reset();
        }
      });
  }

  unsetFlag(): void {
    this.claimItemStore.resetForRetailerReview();
  }

  createNote(): void {
    this.claimItemStore.saveNote(this.form.value.note).subscribe(() => {
      this.form.reset({
        note: '',
      });
      this.form.markAsPristine();
    });
  }

  returnToCurrentClaim(): void {
    if (this.state.get('singleView')) {
      this.onClose();
    } else {
      this.dialogService
        .canLeaveDefaultConfirmationObservable(this.form, this.filesForm.value.length > 0)
        .subscribe(canLeave => {
          if (canLeave) {
            this.clearData();
            this.claimItemStore.returnToCurrentClaim();
          }
        });
    }
  }

  timelineToggled(): void {
    this.anchorNavigation.list = this.timelineViewToggle ? this.timelineNavigationList : this.classicNavigationList;
    setTimeout(() => {
      if (this.anchorNavigation.list.length) {
        this.anchorNavigation.scrollToAnchor(this.anchorNavigation.list[0].anchor);
      }
    }, 0);
  }

  uploadFiles(): void {
    this.claimItemStore.uploadFiles(this.filesForm.value).subscribe(() => {
      this.filesForm.clear();
    });
  }

  print(): void {
    window.print();
  }

  docTypeFilterChanged(filterValue: any): void {
    this.docTypeFilter = filterValue;
  }

  allFilesLoaded(): void {
    this.claimItemStore.allFilesLoaded();
  }

  openMessageHistory(claim: ClaimDetails): void {
    if (this.featureService.allowedMany([this.features.ViewSmsHistory])) {
      this.matDialog.open(ViewMessageHistoryDialogComponent, {
        data: {
          claim,
        },
        disableClose: true,
        autoFocus: false,
      });
    }
  }

  removeFiles(fileIndex: number): void {
    const updatedFiles = this.filesForm.value.filter(file => fileIndex !== file.index);
    this.filesForm.clear();
    updatedFiles.forEach(file => {
      this.filesForm.push(this.fb.control(file));
    });
  }

  completeRepair(): void {
    this.state.set({
      markCompleteLoading: true,
    });
    this.claimsPageService.completeJewelryClaim(this.claimItemStore.get('claim')).pipe(
      finalize(() => {
        this.state.set({
          markCompleteLoading: false,
        });
      }),
    ).subscribe(success => {
      if (success) {
        this.notificationService.next({
          type: NotificationType.Success,
          message: 'Your completion request has been submitted to Montage. The claim will be updated shortly.',
        });
        this.claimItemStore.getClaimById();
      }
    });
  }

  private clearData(): void {
    this.filesForm.clear();
    this.form.reset({
      note: '',
    });
    this.form.markAsPristine();
  }

  private _isShippingLabelAddressMissing(repairAction: IServerRepairAction): boolean {
    return false;
  }
}
