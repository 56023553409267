<div
  [class.th-table__maxHeightLimitation]="maxHeightLimitation"
  [class.th-table_empty-load]="loading && empty">
  <th-table-view
    [data]="data"
    [columns]="columns"
    [hiddenColumns]="hiddenColumns"
    [isClickable]="isClickable"
    [isSortable]="isSortable"
    [rowHeight]="rowHeight"
    [mobileColumnName]="mobileColumnName"
    [sorting]="sorting"
    [selection]="selection"
    [showToolbar]="showToolbar"
    [customCellTemplate]="customCellTemplate"
    (sortChanged)="sortChanged.emit($event)"
    (action)="action.emit($event)"
    (rowClicked)="rowClick.emit($event)"></th-table-view>

<!--  <th-tile-table-loader-->
<!--    *ngIf="loading"-->
<!--    class="th-table__loader"-->
<!--    type="lines-small"-->
<!--    [loading]="!loading">-->
<!--  </th-tile-table-loader>-->
  <th-table-empty *ngIf="!loading && empty" header="No Results Found"></th-table-empty>
</div>

<div
  class="th-table__pagination"
  *ngIf="pagination?.totalCount > 0">
  <th-table-pagination
    [pagination]="pagination"
    [pageSize]="pagination.pageSize"
    (pageChange)="pageChanged.emit($event)"></th-table-pagination>
</div>
<div *ngIf="loading"
     class="th-table__spinner-wrapper">
  <app-loader></app-loader>
</div>
