import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PlanDetailsDialogService } from '@th-common-retailer/shared/dialogs/plan-details/plan-details-dialog.service';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, finalize, map, skip, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { DocumentApi } from '@core/api/document.api';
import { Files } from '@core/interfaces/claims/files.interface';
import { HubEvent, THubMethods } from '@core/models/hub-event.model';
import { MainHub } from '@core/services/hubs/main.hub';
import { NotificationType } from '@shared/modules/notification/enums/notification-type.enum';
import { NotificationService } from '@shared/modules/notification/services/notification.service';

import { RetailerPlanApiService } from '../../api/retailer-plan-api.service';
import { PlanState } from '../../interfaces/plan/plan-item-state.interface';
import { PlansStore } from '../../store/plans/plans.store';

let _state: PlanState = {
  item: null,
  currentId: null,
  loading: false,
  filesLoading: false,
  files: [],
};

@Injectable({
  providedIn: 'root',
})
export class PlanItemFacade {
  public store = new BehaviorSubject<PlanState>(_state);
  private state$ = this.store.asObservable();
  public destroyed$: Subject<boolean> = new Subject<boolean>();

  public detailsOpened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  item$ = this.state$.pipe(
    map(state => state.item),
    distinctUntilChanged(),
  );
  currentId$ = this.state$.pipe(
    map(state => state.currentId),
    distinctUntilChanged(),
  );
  loading$ = this.state$.pipe(
    map(state => state.loading),
    distinctUntilChanged(),
  );
  filesLoading$ = this.state$.pipe(
    map(state => state.filesLoading),
    distinctUntilChanged(),
  );
  files$ = this.state$.pipe(
    map(state => state.files),
    distinctUntilChanged(),
  );

  combinedObservables: Observable<any>[] = [
    this.item$,
    this.currentId$,
    this.loading$,
    this.filesLoading$,
    this.files$,
  ];

  vm$: Observable<PlanState> = combineLatest(this.combinedObservables).pipe(map(this.mapObservables));

  constructor(
    protected retailerPlanApiService: RetailerPlanApiService,
    public notification: NotificationService,
    public router: Router,
    private readonly plansStore: PlansStore,
    private readonly planDetailsDialogService: PlanDetailsDialogService,
    private readonly mainHub: MainHub,
    private readonly documentApi: DocumentApi,
  ) {
    this.mainHub.baseHub.eventBus$.subscribe((event: HubEvent) => {
      const currentId = this.store.getValue().currentId;
      switch (event.name) {
        case THubMethods.ProtectionPlanDocsUploaded:
          if (event.data.planId === +currentId) {
            this.getFiles();
          }
          break;
      }
    });
  }

  setNewProtectionPlanStatus(protectionPlanStatus: number): void {
    if (protectionPlanStatus) {
      const newState = { ...this.store.getValue() };
      newState.item.planInfo.protectionPlanStatus = protectionPlanStatus;
      this.updateState({
        ...newState,
        item: {
          ...newState.item,
          planInfo: {
            ...newState.item.planInfo,
            protectionPlanStatus: protectionPlanStatus,
          },
        },
      });
    }
  }

  getItem(): void {
    this.updateState({ ..._state, loading: true });
    this.retailerPlanApiService
      .detailed(this.store.getValue().currentId)
      .pipe(
        map(planDetails => this.planDetailsDialogService.parsePlanDetails(planDetails)),
        finalize(() => {
          this.updateState({ ..._state, loading: false });
        }),
      )
      .subscribe({
        next: response => {
          this.updateState({ ..._state, item: response });
          if (response.planInfo.consumerPlanName) {
            this.getFiles();
          }
        },
        error: error => {
          if (error.status === 403 || error.status === 404) {
            this.notification.next({
              message: 'You do not have access to this plan.',
              type: NotificationType.Error,
            });
          }
        },
      });
  }

  setCurrentId(id: number) {
    if (window.location.pathname === this.getRouteUrl()) {
      this.router.navigate([this.getRouteUrl()], { queryParams: { id } });
    }
    this.detailsOpened.next(true);
    return this.updateState({ ...this.store.getValue(), currentId: id });
  }

  getCurrentId(): number {
    return this.store.getValue().currentId;
  }

  mapObservables([item, currentId, loading, filesLoading, files]: any): PlanState {
    return { item, currentId, loading, filesLoading, files };
  }

  destroy() {
    this.detailsOpened.next(false);
    this.updateState({ ..._state, item: null });
    if (window.location.pathname === this.getRouteUrl()) {
      this.router.navigate([this.getRouteUrl()]);
    }
  }

  getFiles() {
    this.store.getValue().files = [];
    this.updateState({ ...this.store.getValue() });
    this.updateState({ ...this.store.getValue(), filesLoading: true });
    combineLatest([this.currentId$])
      .pipe(
        filter(([currentId]) => !!currentId),
        take(1),
        switchMap(([currentId]) => this.retailerPlanApiService.getFiles(currentId)),
        tap(files => this.updateState({ ...this.store.getValue(), files })),
        finalize(() => this.updateState({ ...this.store.getValue(), filesLoading: false })),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  updateState(state: PlanState) {
    this.store.next((_state = state));
    return this;
  }

  changePlanDetailsPage(page: number, claimIndex: number) {
    this.updateState({ ...this.store.getValue(), loading: true });
    this.plansStore.updatePagination(page);
    this.plansStore
      .select$('plans')
      .pipe(skip(1), take(1))
      .subscribe(plans => {
        this.setCurrentId(plans[claimIndex].id).getItem();
      });
  }

  allFilesLoaded() {
    this.updateState({ ...this.store.getValue(), filesLoading: false });
  }

  getRouteUrl(): string {
    return '/app/plans';
  }

  uploadFiles(files: Files[]): Observable<void> {
    return this.documentApi.uploadPlanFiles(files, this.store.getValue().currentId);
  }
}
