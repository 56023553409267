import { Injectable } from '@angular/core';

import { IDataSearch } from '../interfaces/data-search.interface';
import { IIdentityString } from '../interfaces/util/identity.interface';
import { BaseSearchRequest, ProtectionPlanRequest } from '../models/search/search-request.model';
import { CrudApiBaseService } from './crud-api.base';

export interface ParentPlanSearchRequest extends BaseSearchRequest {
  retailerId: number;
}

export interface ParentPlanIdsSearchRequest extends ParentPlanSearchRequest {
  retailerPlanIds: string[];
}

@Injectable({
  providedIn: 'root',
})
export class ParentPlanApiService extends CrudApiBaseService<IIdentityString> {
  endpoint = 'parentplan';

  all(searchRequest: ProtectionPlanRequest) {
    return this.httpClient.post<IDataSearch<IIdentityString>>(this.getUrl('all'), searchRequest);
  }

  comparePlans(searchRequest: ParentPlanIdsSearchRequest) {
    return this.httpClient.post<any>(this.getUrl('compareplans'), searchRequest);
  }
}
